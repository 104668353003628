import { Pipe, PipeTransform } from '@angular/core';
import { KendoDropdownItem } from '@ssmm-shared/data/kendo-dropdown-item.interface';
import defaultDropdownItemFactory from '@ssmm-shared/factories/default-dropdown-item-factory';

@Pipe({
  name: 'geoElementeDefaultItem'
})
export class GeoElementeDefaultItemPipe implements PipeTransform {
  dropdownDefaultKREbeneSelectedItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Alle Kreise'
  );

  dropdownDefaultRBEbeneSelectedItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Alle Regierungsbezirke'
  );

  dropdownDefaultItem: KendoDropdownItem = defaultDropdownItemFactory(
    'Bitte wählen'
  );

  transform(selectedGeoEbene: string): KendoDropdownItem {
    return selectedGeoEbene === 'KR'
      ? this.dropdownDefaultKREbeneSelectedItem
      : selectedGeoEbene === 'RB'
      ? this.dropdownDefaultRBEbeneSelectedItem
      : this.dropdownDefaultItem;
  }
}
