<div class="d-flex navbar-wrapper">
  <nav class="navbar navbar-expand-lg navbar-dark p-0 ml-auto">
    <!-- Main Menu View-->
    <kendo-buttongroup #nav *ngIf="startLevel" class="nav navbar-nav ml-auto">
      <!-- [selected] is negated here on purpose to invert the selected style-->
      <button
        *ngFor="let saison of availableSaisons"
        type="button"
        [selected]="!(saison | isSeasonSelected)"
        kendoButton
        [togglable]="true"
        class="nav-item"
        [ngClass]="{ 'nav-item__selected': saison | isSeasonSelected }"
        (click)="loadSaisonNav(saison)"
      >
        {{ saison.displayName }}
      </button>
    </kendo-buttongroup>

    <!-- Within Menu -->
    <kendo-buttongroup
      *ngIf="!startLevel"
      [selection]="'single'"
      class="nav navbar-nav ml-auto"
    >
      <button
        type="button"
        kendoButton
        class="nav-item"
        [routerLink]="['/']"
        [selected]="true"
      >
        Module
      </button>
      <button
        *ngIf="currentSaison"
        type="button"
        kendoButton
        class="nav-item nav-item__selected"
      >
        {{ currentSaison }}
      </button>
    </kendo-buttongroup>
  </nav>
</div>
