<kendo-dialog
  title="{{ dialogTitle }}"
  *ngIf="isDialogOpen"
  (close)="closeDialog()"
>
  <!-- Child UI -->
  <ng-content></ng-content>

  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="closeDialog()">Abbrechen</button>
    <button type="button" kendoButton themeColor="primary" (click)="save()">
      Speichern
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
