/**
 * MachMit Organisationsportal API
 * Diese Schnittstelle bietet Zugriff auf die Funktionalität des Machmit-Organisationsportals. Sie ist als REST-Service konzipiert ([Level 3 im RMM](http://martinfowler.com/articles/richardsonMaturityModel.html)) und erlaubt sowohl das Erstellen und Absenden von Bestellungen wie auch den Import von Produktdaten, die der Prodktauswahl zugrunde liegen.  Die Schnittstelle ist Hypermedia-orientiert: Obwohl in dieser Beschreibung konkrete REST-Pfade aufgelistet sind, sollte der Client davon ausgehen, dass diese sich ändern können. Die jeweils aktuellen Service-Pfade bekommt der Client über die Wurzelressource bzw. über die von ihr aus erreichbaren REST-Pfade. Der Gateway-Server entscheidet über die ausgelieferten Daten und deren Hyperlinks anhand des angemeldeten Benutzers - so ist es z.B. möglich, nachträglich Sichtbarkeitsregeln einzuführen, ohne den Client ändern zu müssen. Für alle Operationen auf einer Ressource gibt es Links, die direkt zu den entsprechenden Service-Aufrufen führen. Die Aufruf-URLs müssen also nicht selbst erst \'zusammengebaut\' werden, sondern werden mit der betreffenden Ressource ausgeliefert. Die Hypermedia-Elemente sind an [HAL](http://stateless.co/hal_specification.html) angelehnt.  Obwohl diese Beschreibung alle Services explizit auflistet, müssen App- und Import-Clients mithilfe der HAL-Hyperlinks in \'_links\' in den Daten navigieren, um von einer Ansicht zur nächsten zu gelangen. Die Einzelbeschreibungen der Link-Elemente geben hier an, welche Daten geliefert werden und wie mit ihnen zu verfahren ist.
 *
 * The version of the OpenAPI document: 3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Gibt an, wie die Daten zwischen Server und Frontend ausgetauscht werden. * STRING: JSON-String, z.B.  \'Foo\' * INT: JSON-Number ohne Nachkommastellen, z.B: 42 * FLOAT: JSON-Number Nachkommastellen, z.B. 3.14158 * BOOLEAN: JSON boolean, z.B. true
 */
export enum FieldType {
  String = 'STRING',
  Int = 'INT',
  Float = 'FLOAT',
  Boolean = 'BOOLEAN'
}
