<button
  class="btn-docs-upload awesome-btn-default"
  kendoButton
  themeColor="primary"
  (click)="onButtonClick()"
>
  <div class="btn-docs-upload__content">
    <div class="btn-docs-upload__content-icon mr-1">
      <fa-icon class="ml-auto" [icon]="['fas', 'paperclip']"></fa-icon>
    </div>
    <div class="ml-auto mt-auto btn-docs-upload__content-amount">
      {{ amountItems }}
    </div>
  </div>
</button>
