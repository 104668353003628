import { CategoryTabItem } from './category-tab-item.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabMetaData } from 'app/competition-organisation/bea-organisation/registered-teams-list/data/tab-meta-data.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'ssmm-category-tabs',
  templateUrl: './category-tabs.component.html'
})
export class CategoryTabsComponent {
  @Input() tabItems: CategoryTabItem[];
  @Output() onTabSelected = new EventEmitter<CategoryTabItem>();
  @Input() selectedIndex: Observable<number>;

  onTabSelect($event: TabMetaData): void {
    // this.selectedIndex = $event.index;

    this.onTabSelected.emit(<CategoryTabItem>{
      ...this.tabItems[$event.index],
      index: $event.index
    });
  }
}
