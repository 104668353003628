import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {
  transform(value: any[], field: string): any {
    if (!_.isArray(value)) {
      return value;
    }

    return value.map(i => i[`${field}`]);
  }
}
