import { Injectable } from '@angular/core';
import { SelectableItem } from '@ssmm-shared/data/models/selectable-item.interface';

@Injectable({
  providedIn: 'root'
})
export class GridCheckboxSelectionService {
  getSelectedItems<T extends SelectableItem>(
    isSelect: boolean,
    allItems: T[]
  ): T[] {
    if (!isSelect) {
      // Keep only items in list which are not changable and already selected
      return allItems.filter(d => !d.aenderbar && d.selektiert);
    }

    // Also keep already selected, but not changable items selected by adding selected to condition
    return allItems.filter(i => i.aenderbar || i.selektiert);
  }
}
