import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@ssmm-shared/data/models/user/user.interface';

@Pipe({
  name: 'userFullName'
})
export class UserFullNamePipe implements PipeTransform {
  transform(user: User): string {
    if (!user) {
      return '—';
    }

    return user.vorname ? user.vorname + ' ' + user.nachname : user.nachname;
  }
}
