<fa-icon
  kendoTooltip
  class="right"
  position="right"
  [tooltipTemplate]="tooltipTemplate"
  filter="[tooltipContent]"
  [attr.tooltipContent]="'placeholder'"
  [icon]="['fas', 'question-circle']"
></fa-icon>
<ng-template #tooltipTemplate let-anchor>
  <span
    *ngIf="anchor.nativeElement.getAttribute('tooltipContent')"
    [innerHTML]="tooltipContent"
  ></span>
</ng-template>
