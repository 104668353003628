import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  constructor() {}

  openDoc(id: string, name: string): void {
    const anchorTag = document.getElementById(id);

    // Open PDF in new tab (not supported in iOS)
    anchorTag.setAttribute('download', name);
    anchorTag.click();
  }
}
