import { AgeGroupPipe } from '@ssmm-shared/pipes/age-group.pipe';
import { Injectable } from '@angular/core';
import { KendoDropdownItem } from '@ssmm-shared/data/kendo-dropdown-item.interface';

@Injectable({ providedIn: 'root' })
export class AgeGroupService {
  constructor(private _ageGroupPipe: AgeGroupPipe) {}

  getAgeGroupItems(
    schoolYear: number,
    min?: number,
    max?: number
  ): KendoDropdownItem[] {
    const ageGroupTextList: string[] = [];
    const ageGroupList: KendoDropdownItem[] = [];

    const limitMin = min ?? 1;
    const limitMax = max ?? 22;

    for (let i = limitMin; i <= limitMax; i++) {
      if (i <= 4 || i >= 8) {
        ageGroupTextList.push(this._ageGroupPipe.transform(i, schoolYear));
      }
    }

    for (let i = 1; i < ageGroupTextList.length + 1; i++) {
      ageGroupList.push(<KendoDropdownItem>{
        text: ageGroupTextList[i - 1],
        value: i
      });
    }

    return ageGroupList;
  }

  getAgeGroupYearItems(schoolYear: number): KendoDropdownItem[] {
    const ageGroupList: KendoDropdownItem[] = [];
    const min = 5;
    const max = 22;

    let currentYear: number;
    for (let i = min; i <= max; i++) {
      currentYear = schoolYear - i;

      ageGroupList.push(<KendoDropdownItem>{
        text: currentYear.toString(),
        value: currentYear
      });
    }

    return ageGroupList;
  }
}
