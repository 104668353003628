<ng-container
  [ngTemplateOutlet]="userDetailsModal"
  [ngTemplateOutletContext]="{ userDetails: user$ ? (user$ | async) : null }"
></ng-container>

<ng-template #userDetailsModal let-user="userDetails">
  <!-- Details Popup -->
  <kendo-dialog
    class="kendo-small-dialog"
    title="{{ 'Details für Nutzer ' + (user | userFullName) }}"
    [width]="800"
    *ngIf="user"
    (close)="close()"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <label>Vorname</label>
        </div>
        <div class="col-md-6">
          <div>{{ user.vorname }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Nachname</label>
        </div>
        <div class="col-md-6">
          <div>{{ user.nachname }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>E-Mail</label>
        </div>
        <div class="col-md-6">
          <div>
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Mobiltelefon</label>
        </div>
        <div class="col-md-6">
          <div *ngIf="user.mobiltelefon; else emptyCellTemplate">
            <a [href]="'tel:' + user.mobiltelefon">{{ user.mobiltelefon }}</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Funktionen</label>
        </div>
        <div class="col-md-6">
          <ng-container
            *ngIf="user.funktionen?.length; else emptyCellTemplate"
            [ngTemplateOutlet]="userFunctionList"
          ></ng-container>
          <ng-template #userFunctionList>
            <div *ngFor="let role of user.funktionen">{{ role }}</div>
          </ng-template>
        </div>
      </div>
    </div>
    <ng-template #emptyCellTemplate>&mdash;</ng-template>
    <kendo-dialog-actions [layout]="'stretched'" class="kendo-actions-close">
      <button type="button" kendoButton (click)="close()">Schließen</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</ng-template>
