import { Pipe, PipeTransform } from '@angular/core';
import { FixtureRound } from '@ssmm-shared/data/models/fixture/fixture-round.enum';

@Pipe({
  name: 'fixtureRound'
})
export class FixtureRoundPipe implements PipeTransform {
  transform(roundKey: string): any {
    return FixtureRound[roundKey];
  }
}
