import { environment } from './../../../../environments/environment';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { LOGGER_LEVEL } from './log-level.token';
import { LogLevel } from './log-level.enum';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    @Inject(LOGGER_LEVEL) private _level: LogLevel,
    private _router: Router
  ) {}

  debug(message: string): void {
    if (this._level < LogLevel.DEBUG) {
      return;
    }

    console.log(this.getLogMsg('DEBUG', message));
  }

  warn(message: string): void {
    if (this._level < LogLevel.WARN) {
      return;
    }

    console.warn(this.getLogMsg('WARN', message));
  }

  error(message: string): void {
    if (this._level < LogLevel.ERROR) {
      return;
    }

    console.error(this.getLogMsg('ERROR', message));
  }

  private getRoute(): string {
    return this._router.url;
  }

  private getLogMsg(level: string, msg: string): string {
    return (
      level +
      ': Version ' +
      environment.version +
      ', Route ' +
      this.getRoute() +
      ': ' +
      msg
    );
  }
}
