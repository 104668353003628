import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxMin'
})
export class MaxMinPipe implements PipeTransform {
  transform(min: string, max?: string): string {
    min = min !== void 0 && min !== null ? min : 'offen';

    if (!max || min === max) {
      return min.toString();
    }
    return max + '—' + min;
  }
}
