import { Saison } from './../data/saison.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationResponse } from '../data/navigation-response.interface';
import { environment } from '../../../environments/environment';
import { NavigationServiceContract } from './navigation.service.interface';

@Injectable({ providedIn: 'root' })
export class NavigationService implements NavigationServiceContract {
  private readonly _baseUrl: string;

  private _navigationSubject = new Subject<NavigationResponse>();

  private _seasonsCached: Saison[];

  constructor(private _http: HttpClient) {
    this._baseUrl =
      environment.apiBaseUrl !== '/'
        ? environment.apiBaseUrl
        : document.location.origin;
  }

  public get seasonsCached(): Saison[] {
    return this._seasonsCached;
  }

  getNavigation(): Observable<NavigationResponse> {
    return this._navigationSubject.asObservable();
  }

  navigateToSub(): void {
    this._navigationSubject.next(null);
  }

  refreshNavigation(url: string): void {
    this.getNavigationForSeason(url).subscribe(res => {
      this._navigationSubject.next(res);
    });
  }

  private getNavigationForSeason(uri: string): Observable<NavigationResponse> {
    const url = new URL(uri, this._baseUrl).href;

    return this._http.get(url).pipe(
      map(res => <NavigationResponse>res),
      tap(res => (this._seasonsCached = res.availableSaisons))
    );
  }
}
