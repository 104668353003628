import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'ssmm-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YesNoDialogComponent {
  @Input() title: string;
  @Input() question: string;
  @Input() width: number;
  @Input() contentClass: string;

  @Output() confirmed = new EventEmitter<void>();
  @Output() aborted = new EventEmitter<void>();

  isDialogOpened: boolean;

  private _confirmCallback: () => void;
  private _cancelCallback: () => void;

  constructor(private _cdr: ChangeDetectorRef) {
    this.width = 450;
    this.contentClass = 'kendo-dialog-content';
  }

  open(
    question?: string,
    confirmCallback?: () => void,
    cancelCallback?: () => void
  ): void {
    if (question) {
      this.question = question;
    }

    this._confirmCallback = confirmCallback;
    this._cancelCallback = cancelCallback;

    this.isDialogOpened = true;

    // We have no setter, so we need to trigger change detection manually
    this._cdr.detectChanges();
  }

  confirm(): void {
    if (this._confirmCallback) {
      this._confirmCallback();
    }

    this.confirmed.emit();
    this.close();
  }

  cancel(): void {
    if (this._cancelCallback) {
      this._cancelCallback();
      this._cancelCallback = null;
    }

    this.aborted.emit();
    this.close();
  }

  close(): void {
    this.isDialogOpened = false;
  }
}
