import { Injectable } from '@angular/core';
import { KeycloakService } from 'app/keycloak-service/keycloak.service';
import { environment } from 'environments/environment';
import { KeycloakConfig } from 'keycloak-js';

@Injectable({ providedIn: 'root' })
export class DynamicAppConfigService {
  setupKeycloak(): Promise<void> {
    return new Promise<void>((resolvePromise, rejectPromise) => {
      console.log('Bootstrapping keycloak...');
      const keycloakConfig: KeycloakConfig = environment.keycloakConfig;

      KeycloakService.init(keycloakConfig, {
        onLoad: 'login-required',
        flow: 'standard',
        checkLoginIframe: false,
        pkceMethod: 'S256'
      })
        .then(() => {
          console.log('Logged in, bootstrapping application...');
          resolvePromise();
        })
        .catch((e: any) => {
          if (!!e.error && e.error === 'access_denied') {
            console.log(
              'Keycloak authentification failed: ' + JSON.stringify(e)
            );
          } else {
            console.log('Error in keycloak bootstrap: ' + JSON.stringify(e));
          }

          rejectPromise();
        });
    });
  }
}
