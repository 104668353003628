import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({ providedIn: 'root' })
export class ToastNotificationService {
  constructor(private _notificationService: NotificationService) {}

  success(msg: string): void {
    this.show('success', msg);
  }

  successChangesSaved(): void {
    this.show('success', 'Änderungen wurden gespeichert.');
  }

  warn(msg: string): void {
    this.show('warning', msg);
  }

  error(msg: string): void {
    this.show('error', msg);
  }

  errorGeneric(): void {
    this.show('error', 'Ein Fehler ist aufgetreten');
  }

  private show(
    style: 'success' | 'none' | 'warning' | 'error' | 'info',
    msg: string
  ) {
    this._notificationService.show({
      content: msg,
      type: {
        style,
        icon: true
      },
      position: {
        horizontal: 'center',
        vertical: 'top'
      },
      hideAfter: 3000
    });
  }
}
