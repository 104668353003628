import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from '@ssmm-shared/services/backend.service';
import { AssignmentBackPath } from '../vw_rb_assignment-list/data/assignment-back-path.interface';
import { User } from '@ssmm-shared/data/models/user/user.interface';
import { UserSelectionServiceContract } from './user-selection.service.contract';

@Injectable({ providedIn: 'root' })
export class UserSelectionService implements UserSelectionServiceContract {
  assignmentUrl: string;
  isUpdate: boolean;
  alternativeSuccessMessage: string;
  backpathToAssign: AssignmentBackPath;
  shouldAssignKr: boolean;
  krAssignmentUrl: string;
  private _assignedBackPath: AssignmentBackPath;

  constructor(private _backendService: BackendService<null, User>) {}

  /**
   * Wrapper around backend service to be able to cache HAL link within a service
   * @param url The HAL link
   * @param user The user object to be assigned
   */
  assignUser(url: string, user: User): Observable<number> {
    const update = this.isUpdate;
    this.isUpdate = false;

    return update
      ? this._backendService.updateItem(url, user)
      : this._backendService.saveItem(url, user);
  }

  getAssignedBackPath(): AssignmentBackPath {
    const backPath = this._assignedBackPath;
    delete this._assignedBackPath;
    return backPath;
  }

  userAssigned(): void {
    this._assignedBackPath = this.backpathToAssign;
    delete this.backpathToAssign;
  }
}
