import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Breadcrumb, BreadcrumbsResolver } from '@exalif/ngx-breadcrumbs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbResolver extends BreadcrumbsResolver {
  resolve(
    route: ActivatedRouteSnapshot,
    // @ts-ignore: State is part of interface
    state: RouterStateSnapshot
  ): Observable<Breadcrumb[]> | Promise<Breadcrumb[]> | Breadcrumb[] {
    // Check if component is assigned in route config
    // or if there is a child with a root url ('')
    // or (when lazily loaded) hasNavRoot is set, which means, there is a lazy child with a root path ('')
    // If not, route to root
    const rootChild =
      route.routeConfig.children &&
      route.routeConfig.children.find(c => c.path === '');

    const pathToNavigate =
      !route.component && !rootChild && !route.routeConfig.data.hasNavRoot
        ? '/module'
        : super.getFullPath(route);

    return <Breadcrumb[]>[
      { text: route.data.breadcrumbs, path: pathToNavigate }
    ];
  }
}
