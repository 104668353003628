import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'ssmm-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent {
  @Input() title: string;
  @Input() content: string;
  @Input() width: number;

  isDialogOpened: boolean;

  constructor(private _cdr: ChangeDetectorRef) {
    this.width = 450;
  }

  open(): void {
    this.isDialogOpened = true;

    // We have no setter, so we need to trigger change detection manually
    this._cdr.detectChanges();
  }

  close(): void {
    this.isDialogOpened = false;
  }
}
