import { User } from './../data/models/user/user.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUser'
})
export class ToUserPipe implements PipeTransform {
  transform(user: User, ...freeUserData: string[]): User {
    if (user) {
      return user;
    }

    return <User>{
      vorname: freeUserData[0] ?? '',
      nachname: freeUserData[1] ?? '',
      email: freeUserData[2] ?? '',
      mobiltelefon: freeUserData[3] ?? ''
    };
  }
}
