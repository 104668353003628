import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { User } from '@ssmm-shared/data/models/user/user.interface';

@Component({
  selector: 'ssmm-vl-assignment-dialog',
  templateUrl: './vl-assignment-dialog.component.html'
})
export class VlAssignmentDialogComponent {
  // #region [OUT]
  @Output()
  vlSelected: EventEmitter<User> = new EventEmitter<User>();
  // #endregion

  title: string;
  isVisible: boolean;

  // #region [OBS]
  vls: User[];
  // #region [OBS]

  constructor(private _cdr: ChangeDetectorRef) {}

  open(title: string, availableVls: User[]): void {
    this.vls = availableVls;
    this.title = title;
    this.isVisible = true;

    this._cdr.markForCheck();
  }

  userSelected(vlAssignee: User): void {
    this.vlSelected.emit(vlAssignee);
    this.close();
  }

  close(): void {
    this.isVisible = false;
    this._cdr.markForCheck();
  }
}
