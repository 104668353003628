<form [formGroup]="searchForm" class="form-group">
  <div class="school-list-search-wrapper">
    <kendo-textbox
      placeholder="Name der Schule..."
      class="form-control"
      formControlName="name"
    ></kendo-textbox>
    <kendo-textbox
      placeholder="Ort..."
      class="form-control"
      formControlName="town"
    ></kendo-textbox>
    <kendo-dropdownlist
      [data]="schoolTypes$ | async"
      [textField]="'name'"
      [defaultItem]="defaultType"
      class="form-control mt-0 mr-1"
      formControlName="schoolType"
      [valueField]="'kuerzel'"
      (selectionChange)="schoolTypeChanged($event)"
    ></kendo-dropdownlist>
  </div>
</form>

<!-- School List -->
<kendo-grid
  [data]="gridData"
  [pageable]="true"
  [pageSize]="state.take"
  [skip]="state.skip"
  (dataStateChange)="refreshList($event)"
>
  <kendo-grid-messages
    noRecords="Es wurde keine Schule gefunden."
  ></kendo-grid-messages>

  <kendo-grid-column title="Name der Schule" field="name"></kendo-grid-column>

  <kendo-grid-column title="Schulart(en)">
    <ng-template kendoGridCellTemplate let-column>
      {{ column.schularten | map : 'name' | join : ', ' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="PLZ" field="ort.plz" [width]="100">
  </kendo-grid-column>

  <kendo-grid-column title="Ort" field="ort.name"></kendo-grid-column>

  <kendo-grid-column
    title="E-Mail der Schulleitung"
    field="emailSchulleitung"
    *ngIf="!isAssignmentMode"
  >
  </kendo-grid-column>

  <kendo-grid-column [width]="100" *ngIf="!isAssignmentMode">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="align-content-center">
        <button
          class="awesome-btn-default"
          kendoButton
          themeColor="primary"
          (click)="editItem(dataItem)"
        >
          <fa-icon [icon]="['fas', 'edit']"></fa-icon>
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="100" *ngIf="!isAssignmentMode">
    <ng-template kendoGridHeaderTemplate>
      <div class="align-content-center">
        <button
          class="awesome-btn-green awesome-btn-auto-margin"
          kendoButton
          themeColor="primary"
          (click)="createItem()"
        >
          <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        </button>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="align-content-center">
        <button
          class="awesome-btn-red"
          kendoButton
          themeColor="primary"
          (click)="openDeleteDialog(dataItem)"
        >
          <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>

  <!-- Assignment button -->
  <kendo-grid-column [width]="100" *ngIf="isAssignmentMode">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="align-content-center">
        <button
          class="awesome-btn-default"
          themeColor="primary"
          kendoButton
          (click)="schoolSelected(dataItem)"
        >
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
