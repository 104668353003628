import { Pipe, PipeTransform } from '@angular/core';
import { GeoElementTreeNode } from 'ssmm-models';
import { KendoDropdownTreeItem } from '@ssmm-shared/data/kendo-dropdown-tree-item.interface';

@Pipe({
  name: 'toKendoDropdownTreeItem'
})
export class ToKendoDropdownTreeItemPipe implements PipeTransform {
  transform(nodes: GeoElementTreeNode[]): KendoDropdownTreeItem[] {
    return nodes.map(n => this.toKendoDropdownTreeItem(n));
  }

  private toKendoDropdownTreeItem(
    geo: GeoElementTreeNode
  ): KendoDropdownTreeItem {
    const childElements =
      !!geo?.children && geo.children.length > 0
        ? geo.children.map(geoChild => this.toKendoDropdownTreeItem(geoChild))
        : [];
    return <KendoDropdownTreeItem>{
      text: geo.geoElement?.name,
      value: geo.geoElement?.kuerzel,
      children: childElements
    };
  }
}
