import {
  UntypedFormGroup,
  UntypedFormArray,
  AbstractControl
} from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'meldeleistungControls'
})
export class MeldeleistungControlsPipe implements PipeTransform {
  transform(formGroup: UntypedFormGroup, specName: string): AbstractControl[] {
    return (<UntypedFormArray>formGroup.get(specName)).controls;
  }
}
