import { School } from '@ssmm-shared/data/models/school/school.interface';
import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ssmm-school-details-dialog',
  templateUrl: './school-details-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchoolDetailsDialogComponent {
  private _school$: Observable<School>;

  constructor(private _cdr: ChangeDetectorRef) {}

  get school$(): Observable<School> {
    return this._school$;
  }

  open(school$: Observable<School>): void {
    this._school$ = school$;
    this._cdr.markForCheck();
  }

  close(): void {
    this._school$ = null;
    this._cdr.markForCheck();
  }
}
