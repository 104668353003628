import { BackendService } from './../services/backend.service';
import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Pipe({
  name: 'pictogram'
})
export class PictogramPipe implements PipeTransform {
  constructor(private _bs: BackendService<null, File>) {}

  transform(uri: string): Observable<SafeUrl> {
    return this._bs.getBlob(uri);
  }
}
