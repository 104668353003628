import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssmm-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss']
})
export class IconTooltipComponent {
  @Input() tooltipContent: String;
}
