import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(value: string[], delimiter = ', '): any {
    if (!_.isArray(value)) {
      return value;
    }

    return value.join(delimiter);
  }
}
