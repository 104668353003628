import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageGroup'
})
export class AgeGroupPipe implements PipeTransform {
  transform(value: number, schoolYear: number): string {
    if (!schoolYear) {
      throw Error('School Year param must be provided');
    }

    if (!value) {
      return 'offen';
    }

    if (value <= 4) {
      return 'Kl. ' + value;
    }

    return (schoolYear - value).toString();
  }
}
