import { UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { KuerzelName } from '@ssmm-shared/data/models/kuerzel-name.interface';

export class ValueKeyListValidators {
  static isNoDuplicate =
    (assignedValues: KuerzelName[]): ValidatorFn =>
    (ctl: AbstractControl) => {
      const formGroup = <UntypedFormGroup>ctl;

      if (!formGroup?.controls) {
        return null;
      }

      if (!assignedValues || assignedValues.length === 0) {
        return null;
      }

      const attributeKuerzel = formGroup.controls.attributeKuerzel.value;
      const attributeName = formGroup.controls.attributeName.value;

      if (
        !assignedValues.find(
          x => x.kuerzel === attributeKuerzel || x.name === attributeName
        )
      ) {
        return null;
      }

      return { error: 'Der neue Wert und sein Kürzel müssen eindeutig sein' };
    };
}
