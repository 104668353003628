import { Component } from '@angular/core';

@Component({
  selector: 'ssmm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  isMenuOpen: boolean;

  readonly navItems = [
    {
      key: 'Allgemeine Hilfe',
      value: 'https://www.machmit-bw.de/service/hilfe/allgemein'
    },
    {
      key: 'Datenschutz',
      value: 'https://www.machmit-bw.de/service/recht/datenschutz'
    },
    {
      key: 'Zur Broschüre',
      value: 'https://www.machmit-bw.de'
    },
    {
      key: 'Impressum',
      value: 'https://www.machmit-bw.de/impressum'
    }
  ];

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
