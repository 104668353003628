<footer class="footer mt-auto">
  <div class="container">
    <div class="footer-container d-flex justify-content-between">
      <p class="my-auto">
        &copy; Stiftung Sport in der Schule in Baden-Württemberg
      </p>

      <nav>
        <!-- Wide screens - show all items -->
        <ul class="nav-wrapper__wide">
          <ssmm-nav-item
            *ngFor="let item of navItems"
            [item]="item"
          ></ssmm-nav-item>
        </ul>

        <!-- Smaller screens - collapsible items -->
        <div class="nav-wrapper__collapsed navbar-dark">
          <div
            class="nav-wrapper-collapsed-items"
            [ngClass]="{ 'nav-wrapper-collapsed-items__open': isMenuOpen }"
          >
            <ssmm-nav-item
              *ngFor="let item of navItems"
              [item]="item"
            ></ssmm-nav-item>
          </div>

          <button
            class="mr-auto navbar-toggler"
            type="button"
            title="Menü öffnen/schließen"
            (click)="toggleMenu()"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </div>
  </div>
</footer>
