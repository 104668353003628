import { HalService } from '../../shared/services/hal.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Saison } from '../../services/data/saison.interface';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LogService } from '@ssmm-shared/services/log/log.service';

@Component({
  selector: 'ssmm-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  startLevel = true;
  currentSaison = '';
  availableSaisons: Saison[];
  private _navSubscription: Subscription;

  constructor(
    private _navService: NavigationService,
    private _halService: HalService,
    private _logger: LogService
  ) {}

  ngOnInit(): void {
    this._navSubscription = this._navService.getNavigation().subscribe(res => {
      if (res) {
        this.startLevel = true;

        this.availableSaisons = res.availableSaisons;
      } else {
        this.startLevel = false;
        this.currentSaison = this._halService.getSaisonName();
      }
    });
  }

  ngOnDestroy(): void {
    if (this._navSubscription) {
      this._navSubscription.unsubscribe();
    }
  }

  loadSaisonNav(saison: Saison): void {
    const navUri = saison._links?.mm_navigation?.href;
    if (!navUri) {
      this._logger.error(`Saison ${saison.displayName} has no HAL link set`);
      return;
    }

    this._navService.refreshNavigation(navUri);
  }
}
