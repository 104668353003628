import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { EqualsValidator } from '@ssmm-shared/validators/equals-validator';
import mobilePhonePattern from '@ssmm-shared/constants/mobile-phone-pattern';
import getValueOrNull from '@ssmm-shared/helpers/get-value-or-null';
import { UserCreationAndAssignmentDialogData } from '@ssmm-shared/views/user-creation-and-assignment-dialog/data/user-creation-and-assignment-dialog-data.interface';
import { BackendService } from '@ssmm-shared/services/backend.service';
import { User } from '@ssmm-shared/data/models/user/user.interface';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { ToastNotificationService } from '@ssmm-shared/services/toast-notification.service';

@Component({
  selector: 'ssmm-user-creation-and-assignment-dialog',
  templateUrl: './user-creation-and-assignment-dialog.component.html'
})
export class UserCreationAndAssignmentDialogComponent implements OnDestroy {
  @Output()
  assignUserRequested = new EventEmitter<UserCreationAndAssignmentDialogData>();

  isDialogOpened: boolean;
  dialogTitle: string;
  userCreationForm: UntypedFormGroup;
  wasSaveRequested: boolean;
  isRbBeauftragung: boolean;
  isSaveInProgress: boolean;

  private _unsubscribe: Subject<void> = new Subject<void>();
  private _nutzerErstellenUrl: string;

  constructor(
    private _fb: UntypedFormBuilder,
    private _createUserbackendService: BackendService<User, User>,
    private _toasterService: ToastNotificationService
  ) {}

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  open(beauftragung: string, nutzerErstellenUrl: string): void {
    this.isDialogOpened = true;
    this.dialogTitle = `Nutzer erstellen und Beauftragung ${beauftragung} zuweisen`;
    this.isRbBeauftragung = beauftragung === 'RB';
    this._nutzerErstellenUrl = nutzerErstellenUrl;

    this.setupForm();
  }

  close(): void {
    this.isDialogOpened = false;
  }

  onSaveAndAssignRequested(): void {
    this.wasSaveRequested = true;
    this.userCreationForm.markAllAsTouched();

    if (!this.userCreationForm.valid) {
      return;
    }

    this.isSaveInProgress = true;

    const value = this.userCreationForm.value;
    const newUser = {
      vorname: getValueOrNull(value.firstName),
      nachname: getValueOrNull(value.lastName),
      email: getValueOrNull(value.emailGroup?.email),
      mobiltelefon: getValueOrNull(value.phone)
    };

    this._createUserbackendService
      .saveItemWithResponseType(this._nutzerErstellenUrl, newUser)
      .pipe(
        takeUntil(this._unsubscribe),
        catchError(err => {
          if (err.status === 409) {
            this._toasterService.error(
              `Es konnte nicht gespeichert werden,
              weil bereits ein Nutzer mit dieser Mail-Adresse
              im Portal vorhanden ist.
              Bitte prüfen Sie die Daten des anzulegenden Nutzers.`
            );
            this.isSaveInProgress = false;
            return EMPTY;
          }

          throw err;
        }),
        tap(createdUser => {
          this.assignUserRequested.emit(<UserCreationAndAssignmentDialogData>{
            user: createdUser,
            shouldAssignKr: value.shouldAssignKr
          });
        }),
        tap(() => this.close())
      )
      .subscribe();
  }

  private setupForm(): void {
    this.userCreationForm = this._fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailGroup: this._fb.group(
        {
          email: ['', [Validators.required, Validators.email]],
          emailRepeat: ['', Validators.required]
        },
        { validators: EqualsValidator.areEqual }
      ),
      phone: [
        '',
        [
          Validators.required,
          Validators.maxLength(20),
          Validators.pattern(mobilePhonePattern)
        ]
      ],
      isDataProcessingAllowed: [false, Validators.requiredTrue]
    });

    if (!this.isRbBeauftragung) {
      return;
    }

    this.userCreationForm.addControl(
      'shouldAssignKr',
      new UntypedFormControl(false)
    );
  }
}
