import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _isLoading = new BehaviorSubject<boolean>(false);

  public get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  notify(isLoading: boolean): void {
    this._isLoading.next(isLoading);
  }
}
