<ng-container
  [ngTemplateOutlet]="schoolDetailsModal"
  [ngTemplateOutletContext]="{
    schoolDetails: school$ ? (school$ | async) : null
  }"
></ng-container>

<ng-template #schoolDetailsModal let-school="schoolDetails">
  <!-- Details Popup -->
  <kendo-dialog
    class="kendo-small-dialog"
    title="{{ 'Details für Schule ' + school.name }}"
    *ngIf="school"
    (close)="close()"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <label>Name</label>
        </div>
        <div class="col-md-6">
          <div>{{ school.name }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Kurzname</label>
        </div>
        <div class="col-md-6">
          <ng-container *ngIf="school.kurzname; else emptyCellTemplate">{{
            school.kurzname
          }}</ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Dienststellenschlüssel</label>
        </div>
        <div class="col-md-6">
          <div>{{ school.dienststellenschluessel }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Schulart(en)</label>
        </div>
        <div class="col-md-6">
          <ng-container
            *ngIf="school.schularten?.length; else emptyCellTemplate"
            [ngTemplateOutlet]="schoolTypeCtl"
          ></ng-container>
          <ng-template #schoolTypeCtl>
            <div *ngFor="let schulart of school.schularten">
              {{ schulart.name }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Straße Hausnummer</label>
        </div>
        <div class="col-md-6">
          <div>{{ school.adresse }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>PLZ Ort</label>
        </div>
        <div class="col-md-6">
          <div>{{ school.ort.plz }} {{ school.ort.name }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>E-Mail Schulleitung</label>
        </div>
        <div class="col-md-6">
          <a href="mailto:{{ school.emailSchulleitung }}">{{
            school.emailSchulleitung
          }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>E-Mail Poststelle</label>
        </div>
        <div class="col-md-6">
          <a href="mailto:{{ school.emailPoststelle }}">{{
            school.emailPoststelle
          }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Telefon</label>
        </div>
        <div class="col-md-6">
          <ng-container *ngIf="school.telefon; else emptyCellTemplate">
            <a [href]="'tel:' + school.telefon">{{ school.telefon }}</a>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label>Fax</label>
        </div>
        <div class="col-md-6">
          <ng-container *ngIf="school.fax; else emptyCellTemplate">
            <a [href]="'tel:' + school.fax">{{ school.fax }}</a></ng-container
          >
        </div>
      </div>
    </div>

    <ng-template #emptyCellTemplate>&mdash;</ng-template>

    <kendo-dialog-actions [layout]="'stretched'">
      <button type="button" kendoButton (click)="close()">Schließen</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</ng-template>
