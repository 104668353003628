<div [formGroup]="controlContainer.control">
  <!-- Vorname -->
  <div class="form-group row">
    <label class="col-md-4 col-form-label">* Vorname</label>
    <div class="col-md-8">
      <!-- Only first name - full width -->
      <div>
        <input kendoTextBox class="form-control" formControlName="firstName" />
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userFirstNameRef"
        ></ssmm-errors>
      </div>
    </div>
  </div>

  <!-- Nachname -->
  <div class="form-group row">
    <label class="col-md-4 col-form-label">* Nachname</label>
    <div class="col-md-8">
      <input kendoTextBox class="form-control" formControlName="lastName" />
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="userLastNameRef"
      ></ssmm-errors>
    </div>
  </div>

  <ng-container formGroupName="emailGroup">
    <!-- Mail-Adresse -->
    <div class="form-group row">
      <label class="col-md-4 col-form-label">* Mail-Adresse</label>
      <div class="col-md-8">
        <input kendoTextBox class="form-control" formControlName="email" />
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userEmailRef"
        ></ssmm-errors>
      </div>
    </div>

    <!-- Mail-Adresse wiederholen -->
    <div class="form-group row">
      <label class="col-md-4 col-form-label">* Mail-Adresse wiederholen</label>
      <div class="col-md-8">
        <input
          kendoTextBox
          class="form-control"
          formControlName="emailRepeat"
        />
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userEmailRepeatRef"
        ></ssmm-errors>
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userEmailGroupRef"
        ></ssmm-errors>
      </div>
    </div>
  </ng-container>

  <!-- Mobile-Phone -->
  <div class="form-group row">
    <label class="col-md-4 col-form-label">* Mobiltelefon</label>
    <div class="col-md-8">
      <div class="p-relative">
        <input kendoTextBox class="form-control" formControlName="phone" />
        <ssmm-icon-tooltip
          [tooltipContent]="tooltipContent"
        ></ssmm-icon-tooltip>
      </div>
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="userPhoneRef"
      ></ssmm-errors>
    </div>
  </div>

  <!-- Kreisbeauftragung CB -->
  <ng-container *ngIf="isRbBeauftragung">
    <div class="row mb-3">
      <div class="col-md-12 d-flex align-items-center">
        <input
          id="assignKrCtl"
          type="checkbox"
          kendoCheckBox
          formControlName="shouldAssignKr"
        />
        <label class="k-checkbox-label ml-2" for="assignKrCtl"
          >Zusätzlich auch Kreisbeauftragung zuweisen</label
        >
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="shouldAssignKrRef"
        ></ssmm-errors>
      </div>
    </div>
  </ng-container>

  <hr />

  <!-- Einverständniserklärung -->
  <div class="row mb-3">
    <div class="col-md-12 d-flex align-items-center">
      <input
        id="confirmationDataProcessingCtl"
        type="checkbox"
        kendoCheckBox
        formControlName="isDataProcessingAllowed"
      />
      <label class="k-checkbox-label ml-2" for="confirmationDataProcessingCtl"
        >* Die Einverständniserklärung der eingetragenen Person zur Speicherung
        und Weitergabe ihrer personenbezogenen Daten liegt mir vor.</label
      >
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="isDataProcessingAllowedRef"
      ></ssmm-errors>
    </div>
  </div>
</div>
