import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';
import { uniq, isNumber } from 'lodash';
import dayjs from 'dayjs';

@Component({
  selector: 'ssmm-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent {
  private static readonly _errorMessages = {
    required: () => 'Pflichtfeld',
    requiredDropdown: () => 'Bitte wählen',
    email: () => 'Die E-Mailadresse ist ungültig',
    min: (params: any) => 'Der kleinste wählbare Wert ist ' + params.min,
    max: (params: any) => 'Der größte wählbare Wert ist ' + params.max,
    minlength: (params: any) =>
      'Die minimale Anzahl von Zeichen ist ' + params.requiredLength,
    maxlength: (params: any) =>
      'Die maximale Anzahl von Zeichen ist ' + params.requiredLength,
    // Kendo Min Date Error
    minError: (params: any) =>
      'Der kleinste wählbare Wert ist ' +
      (isNumber(params.minValue)
        ? params.minValue
        : dayjs(<Date>params.minValue).format('DD.MM.YYYY')),
    // Kendo Max Date Error
    maxError: (params: any) =>
      'Der größte wählbare Wert ist ' +
      (isNumber(params.maxValue)
        ? params.maxValue
        : dayjs(<Date>params.maxValue).format('DD.MM.YYYY')),
    // Custom Max Date error
    maxDate: (params: string) =>
      'Das späteste auswählbare Datum ist ' +
      dayjs(params).format('DD.MM.YYYY'),
    pattern: (params: any) =>
      'Folgendes Zeichenmuster muss eingehalten werden: ' +
      params.requiredPattern,
    patternError: (params: any) =>
      'Folgendes Zeichenmuster muss eingehalten werden: ' + params.mask,
    error: (params: any) => params
  };

  @Input() control: AbstractControlDirective | AbstractControl;
  @Input() isValidationActive: boolean;

  showErrors(): boolean {
    return (
      this.isValidationActive &&
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched)
    );
  }

  errors(): string[] {
    return uniq(
      Object.keys(this.control.errors).map(field =>
        this.getMessage(field, this.control.errors[field])
      )
    );
  }

  private getMessage(type: string, params: any): string {
    const errorType = ErrorsComponent._errorMessages[type];
    if (!errorType) {
      console.error('unknown error type ' + type, params);
      return 'Fehler ' + type;
    }

    return errorType(params);
  }
}
