<kendo-dialog
  title="{{ title }}"
  *ngIf="isDialogOpened"
  (close)="close()"
  [minWidth]="250"
  [width]="width"
>
  <p class="kendo-dialog-content">
    {{ content }}
  </p>
  <ng-content></ng-content>
  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="close()">Schließen</button>
  </kendo-dialog-actions>
</kendo-dialog>
