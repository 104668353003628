import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ssmm-save-cancel-dialog',
  templateUrl: './save-cancel-dialog.component.html'
})
export class SaveCancelDialogComponent {
  @Input() dialogTitle: string;
  @Output() saveRequested = new EventEmitter();

  isDialogOpen: boolean;

  constructor() {
    this.isDialogOpen = true;
  }

  /* openDialog(): void {
    this.isDialogOpen = true;
  } */

  closeDialog(): void {
    this.isDialogOpen = false;
  }

  save(): void {
    this.saveRequested.emit();
    this.closeDialog();
  }
}
