import { UntypedFormGroup } from '@angular/forms';
import { FieldFormat } from '../../data/models/fixture/field-format.enum';
import { Component, Input } from '@angular/core';
import maskedTextboxRules from '@ssmm-shared/data/masked-textbox-rules';
import { FieldSpec } from 'ssmm-models';

@Component({
  selector: 'ssmm-meldeleistungen',
  templateUrl: './meldeleistungen.component.html'
})
export class MeldeleistungenComponent {
  @Input() specs: FieldSpec[];

  @Input() wasSaveRequested: boolean;

  @Input() meldeleistungFormGroup: UntypedFormGroup;

  @Input() isStaffel: boolean;

  fieldFormatDefault = FieldFormat.TYPE_DEFAULT;

  rules = maskedTextboxRules;
}
