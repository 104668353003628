import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  OnDestroy
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { State } from '@progress/kendo-data-query';
import {
  DataStateChangeEvent,
  GridDataResult
} from '@progress/kendo-angular-grid';
import { SchoolType } from '@ssmm-shared/data/models/school/school-type.interface';
import { LogService } from '../services/log/log.service';
import { Observable, merge } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { SchoolResponse } from '../../user-management/services/data/school-response.interface';
import { School } from '@ssmm-shared/data/models/school/school.interface';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap
} from 'rxjs/operators';
import { SchoolSearch } from './school-search.interface';

@Component({
  selector: 'ssmm-school-list-with-search',
  templateUrl: './school-list-with-search.component.html',
  styleUrls: ['./school-list-with-search.component.scss']
})
export class SchoolListWithSearchComponent implements OnInit, OnDestroy {
  @Input() listSchoolsUri: string;
  @Input() schoolTypesUri: string;
  @Input() isAssignmentMode: boolean;
  @Input() selectedSchoolType: SchoolType;
  @Output() openDeleteDialogEvent = new EventEmitter<School>();
  @Output() openEditDialogEvent = new EventEmitter<School>();
  @Output() openCreateDialogEvent = new EventEmitter<void>();
  @Output() schoolSelectedEvent = new EventEmitter<School>();
  @Output() schoolTypeChangedEvent = new EventEmitter<SchoolType>();

  title: string;

  /* Grid settings */
  gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 10
  };

  searchForm: UntypedFormGroup;
  defaultType = { name: 'Schulart auswählen', kuerzel: null };

  schoolTypes$: Observable<SchoolType[]>;
  searchInputChanged$: Observable<SchoolSearch>;
  private _refreshSubject: Subject<SchoolSearch>;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _fb: UntypedFormBuilder,
    private _backendService: BackendService<SchoolResponse, School>,
    private _schoolTypesBackend: BackendService<SchoolType[], SchoolType>,
    private _log: LogService
  ) {}

  ngOnInit(): void {
    this.searchForm = this._fb.group({
      name: '',
      town: '',
      schoolType: this.defaultType
    });

    if (!this.listSchoolsUri) {
      this._log.error('No link provided to list schools.');
      return;
    }

    if (!this.schoolTypesUri) {
      this._log.error('No link provided to list school types.');
      return;
    }

    this.schoolTypes$ = this._schoolTypesBackend.getItems(this.schoolTypesUri);
    this._refreshSubject = new Subject();

    this.searchInputChanged$ = this.searchForm.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      tap(() => (this.state.skip = 0)),
      distinctUntilChanged()
    );

    merge(this.searchInputChanged$, this._refreshSubject.asObservable())
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(searchTerm => {
        this.getSchools(
          this.listSchoolsUri,
          this.state.take,
          this.state.skip,
          this.getSearchTerm(searchTerm)
        );
      });

    if (this.selectedSchoolType) {
      this.searchForm.get('schoolType').setValue(this.selectedSchoolType);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  openDeleteDialog(school: School): void {
    this.openDeleteDialogEvent.emit(school);
  }

  editItem(school: School): void {
    this.openEditDialogEvent.emit(school);
  }

  createItem(): void {
    this.openCreateDialogEvent.emit();
  }

  schoolSelected(school: School): void {
    this.schoolSelectedEvent.emit(school);
  }

  schoolTypeChanged(schoolType: SchoolType): void {
    this.schoolTypeChangedEvent.emit(schoolType);
  }

  /**
   * Paging Kendo Grid event
   * @see https://www.telerik.com/kendo-angular-ui/components/grid/data-binding/
   * @param state The adapted state of the Grid view
   */
  refreshList(state: DataStateChangeEvent): void {
    this.state = state;
    this.refresh();
  }

  refresh(): void {
    this._refreshSubject.next(this.searchForm.value);
  }

  private getSchools(
    url: string,
    pageSize: number,
    skip: number,
    searchTerm = ''
  ): void {
    this._backendService
      .getItems(url, pageSize, skip, searchTerm)
      .pipe()
      .subscribe(res => {
        this.gridData = {
          data: res.items,
          total: res.range ? res.range.count : 0
        };
      });
  }

  private getSearchTerm(searchInput: SchoolSearch): string {
    if (!searchInput) {
      return '';
    }
    const params: string[] = [];
    if (searchInput.name) {
      params.push(`name=${searchInput.name}`);
    }
    if (searchInput.town) {
      params.push(`ort=${searchInput.town}`);
    }
    if (searchInput.schoolType.kuerzel) {
      params.push(`schulartKuerzel=${searchInput.schoolType.kuerzel}`);
    }

    return params ? params.join('&') : '';
  }
}
