import { FieldFormat } from '../data/models/fixture/field-format.enum';
import { Pipe, PipeTransform } from '@angular/core';
import { FieldType } from 'ssmm-models';

@Pipe({
  name: 'dataFormatMask'
})
export class DataFormatMaskPipe implements PipeTransform {
  transform(format: FieldFormat, type: FieldType | null): string {
    if (!type) {
      return '';
    }

    switch (format) {
      case FieldFormat.DD_DD_DD__MINUTES_SECONDS_HUNDRETH:
        return 'H0:H0,00';
      case FieldFormat.DD_DD__SECONDS_HUNDRETH:
        return '00,00';
      case FieldFormat.D_DD__METRES_CENTIMETRES:
        return '0,00';
      case FieldFormat.DD_DD__METRES_CENTIMETRES:
        return '00,00';
      case FieldFormat.DD_DD__POINTS_DECIMAL:
        return '00,00';
      case FieldFormat.TYPE_DEFAULT:
        if (type === FieldType.Int) {
          return '00';
        }
        if (type === FieldType.Float) {
          return '000,00';
        }
        throw Error(
          `Data format not configured. Format: ${format}, Type: ${type}`
        );
      default:
        throw new Error('Unknown field format ' + format);
    }
  }
}
