import { of as observableOf, Observable } from 'rxjs';
import { BackendServiceContract } from './backend.service.interface';
import { Injectable } from '@angular/core';
import { UploadDocument } from '@ssmm-shared/data/models/document/upload-document.interface';
import { UploadDocumentLinks } from '@ssmm-shared/data/models/document/upload-document-links.interface';
import { HALLink } from '@ssmm-shared/data/models/hal-link.interface';
import { delay } from 'rxjs/operators';

@Injectable()
export class BackendServiceMock<T, U> implements BackendServiceContract<T, U> {
  // @ts-ignore: part of interface
  getItem(url: string): Observable<T> {
    return observableOf<T>();
  }

  getItems(
    // @ts-ignore: part of interface
    url: string,
    // @ts-ignore: part of interface
    pageSize = 0,
    // @ts-ignore: part of interface
    skip = 0,
    // @ts-ignore: part of interface
    filter = ''
  ): Observable<T> {
    return observableOf<T>();
  }

  // @ts-ignore: part of interface
  deleteItem(url: string): Observable<boolean> {
    return observableOf<boolean>(true);
  }

  // @ts-ignore: part of interface
  saveItem(url: string, item: U): Observable<number> {
    return observableOf<number>(200);
  }

  // @ts-ignore: part of interface
  saveItemWithResponseType(url: string, item: U): Observable<T> {
    return observableOf<T>(<T>{});
  }

  // @ts-ignore: part of interface
  saveItems(url: string, items: U[]): Observable<boolean> {
    return observableOf<boolean>(true);
  }

  // @ts-ignore: part of interface
  updateItem(url: string, saison: U): Observable<number> {
    return observableOf<number>(200);
  }

  uploadDocument(
    // @ts-ignore: part of interface
    url: string,
    // @ts-ignore: part of interface
    item: File
  ): Observable<UploadDocument> {
    return observableOf(<UploadDocument>{
      name: item.name,
      _links: <UploadDocumentLinks>{
        self: <HALLink>{ href: 'lorem' },
        mm_dokument_delete: <HALLink>{ href: 'ipsum' }
      }
    }).pipe(delay(5000));
  }
}
