import { NgModule } from '@angular/core';
import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome';

// Solid icons
import {
  faCheck,
  faEdit,
  faEllipsisH,
  faEye,
  faFlag,
  faInfoCircle,
  faPlus,
  faShare,
  faTrashAlt,
  faUserCheck,
  faUserCircle,
  faFilter,
  faCalendarCheck,
  faUserFriends,
  faMinusCircle,
  faEnvelope,
  faImage,
  faListOl,
  faArrowsAltV,
  faPaperclip,
  faDownload,
  faTasks,
  faUsers,
  faUserEdit,
  faRedoAlt,
  faRandom,
  faClipboardList,
  faExclamationCircle,
  faTimes,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';

// Regular icons
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule]
})
export class AwesomeIconsModule {
  constructor(library: FaIconLibrary) {
    // Import
    library.addIcons(
      faUserCircle,
      faEdit,
      faPlus,
      faEye,
      faCheck,
      faInfoCircle,
      faTrashAlt,
      faUserCheck,
      faFlag,
      faShare,
      faEllipsisH,
      faFilter,
      faCalendarCheck,
      faUserFriends,
      faMinusCircle,
      faEnvelope,
      faImage,
      faCheckSquare,
      faSquare,
      faListOl,
      faArrowsAltV,
      faPaperclip,
      faDownload,
      faTasks,
      faUsers,
      faUserEdit,
      faRedoAlt,
      faRandom,
      faClipboardList,
      faExclamationCircle,
      faTimes,
      faQuestionCircle
    );
  }
}
