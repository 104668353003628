<kendo-dialog [title]="title" *ngIf="isDialogOpen" (close)="close()">
  <ssmm-user-list
    [getUsersUri]="getUsersUri"
    [isUserSelection]="true"
    (userSelected)="onUserSelected($event)"
  ></ssmm-user-list>
  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="close()">Abbrechen</button>
  </kendo-dialog-actions>
</kendo-dialog>
