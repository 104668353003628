<kendo-dialog
  title="{{ title }}"
  *ngIf="isDialogOpened"
  (close)="cancel()"
  [minWidth]="250"
  [width]="width"
>
  <p [class]="contentClass" *ngIf="question" [innerHTML]="question"></p>
  <ng-content></ng-content>
  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="cancel()">Nein</button>
    <button type="button" kendoButton themeColor="primary" (click)="confirm()">
      Ja
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
