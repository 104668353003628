import { Saison } from './../../../services/data/saison.interface';
import { HalService } from './../../../shared/services/hal.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSeasonSelected'
})
export class IsSeasonSelectedPipe implements PipeTransform {
  constructor(private _halService: HalService) {}

  transform(saison: Saison): boolean {
    const seasonLinkSelected = this._halService.getSaisonLink();

    const isSelected = saison._links.mm_navigation.href === seasonLinkSelected;

    return isSelected;
  }
}
