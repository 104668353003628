<fieldset [formGroup]="meldeleistungFormGroup" *ngIf="specs?.length">
  <h2>Meldeleistungen<span *ngIf="isStaffel"> Staffeln</span>:</h2>
  <div class="row mb-3" *ngFor="let spec of specs" [formArrayName]="spec.name">
    <ng-container
      *ngFor="
        let meldeleistung of meldeleistungFormGroup
          | meldeleistungControls: spec.name;
        let i = index
      "
    >
      <label class="col-md-5 col-form-label"
        ><span *ngIf="spec.mandatory">* </span
        ><span>{{ spec.displayName }}</span
        ><span *ngIf="spec.arraySize > 1">
          &mdash; Staffel {{ i + 1 }}</span
        ></label
      >

      <kendo-maskedtextbox
        *ngIf="
          spec.format !== fieldFormatDefault;
          else meldeleistungFieldFormatDefaultInputCtl
        "
        class="form-control col-md-4"
        [mask]="spec.format | dataFormatMask: spec.type"
        [rules]="rules"
        [formControl]="meldeleistung"
      ></kendo-maskedtextbox>

      <ng-template #meldeleistungFieldFormatDefaultInputCtl>
        <kendo-numerictextbox
          class="form-control col-md-4"
          [formControl]="meldeleistung"
        >
        </kendo-numerictextbox>
      </ng-template>

      <div class="col-md-3 mt-auto mb-auto">
        {{ spec.format | dataFormatPrompt }}
      </div>

      <ssmm-errors
        class="col-md-12 mt-1"
        [control]="meldeleistung"
        [isValidationActive]="wasSaveRequested"
      ></ssmm-errors>
    </ng-container>
  </div>
</fieldset>
