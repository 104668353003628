import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BackendService } from '../../../shared/services/backend.service';

@Component({
  selector: 'ssmm-pictogram',
  templateUrl: './pictogram.component.html'
})
export class PictogramComponent {
  @Input() uri: string;

  @Input() height = '25px';
  @Input() width = '25px';

  @Output() imageLoaded = new EventEmitter<void>();

  imageUrl: SafeUrl;

  constructor(private backendService: BackendService<null, null>) {}

  preLoadImage(): void {
    if (this.uri) {
      this.backendService.getBlob(this.uri).subscribe(
        safeUrl => {
          this.imageUrl = safeUrl;
          this.imageLoaded.emit();
        },
        () => {
          // Emit the event even if the image fails to load
          this.imageLoaded.emit();
        }
      );
    } else {
      // Emit the event if there is no image to load
      this.imageLoaded.emit();
    }
  }
}
