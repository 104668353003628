<ng-container
  *ngIf="uri; else emptyPictoTemplate"
  [ngTemplateOutlet]="pictogramTemplate"
  [ngTemplateOutletContext]="{
    safeUrl: uri | pictogram | async
  }"
></ng-container>
<ng-template #pictogramTemplate let-url="safeUrl">
  <img [src]="url" [ngStyle]="{ height: height, width: width }" />
</ng-template>
<ng-template #emptyPictoTemplate>
  <fa-icon [icon]="['fas', 'image']"></fa-icon>
</ng-template>
