import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ssmm-btn-upload',
  templateUrl: './btn-upload.component.html',
  styleUrls: ['./btn-upload.component.scss']
})
export class BtnUploadComponent {
  @Input() amountItems: number;
  @Output() btnClick = new EventEmitter<unknown>();

  onButtonClick(): void {
    this.btnClick.next();
  }
}
