import { from as observableFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { KeycloakService } from './keycloak.service';

@Injectable()
export class KeycloakHttpInterceptor implements HttpInterceptor {
  constructor(private _keycloakService: KeycloakService) {}

  /**
   * Add the Bearer Auth Token to a HTTP backend request
   * @see https://www.illucit.com/angular/en-angular-5-httpinterceptor-add-bearer-token-to-httpclient-requests/
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this._keycloakService.authenticated()) {
      return next.handle(request);
    }

    return observableFrom(this.handleAccess(request, next));
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const token = await this._keycloakService.getToken();

    // Workaround for POSTs without body - our BE Cors Filter has bug
    // See https://github.com/eBay/cors-filter/issues/17
    if (request.method === 'POST' && request.body === null) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
    } else if (!request.headers.has('Accept')) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json;charset=UTF-8',
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).toPromise();
  }
}
