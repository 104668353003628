import { finalize } from 'rxjs/operators';
import { LoadingService } from 'app/services/loading.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LoadingHttpInterceptor implements HttpInterceptor {
  constructor(private _loadingService: LoadingService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this._loadingService.notify(true);

    return next
      .handle(req)
      .pipe(finalize(() => this._loadingService.notify(false)));
  }
}
