import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModulesListComponent } from './modules-list/modules-list.component';
import { HalGuard } from './shared/services/guards/hal-guard';
import { BreadcrumbResolver } from './shared/services/breadcrumb-resolver.service';
import { KeycloakGuard } from './keycloak-service/keycloak.guard';
import { StateGuard } from 'ngx-state-stack';
import { ModuleWrapperComponent } from './module-wrapper/module-wrapper.component';

const routes: Routes = [
  {
    path: 'module',
    data: { breadcrumbs: 'Module' },
    component: ModuleWrapperComponent,
    canActivate: [KeycloakGuard],
    canActivateChild: [StateGuard],
    children: [
      { path: '', component: ModulesListComponent, pathMatch: 'full' },
      {
        path: 'saison',
        loadChildren: () =>
          import('./season/season.module').then(m => m.SeasonModule),
        data: { breadcrumbs: BreadcrumbResolver },
        canLoad: [KeycloakGuard, HalGuard],
        canActivateChild: [KeycloakGuard]
      },
      {
        path: 'nutzer',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            m => m.UserManagementModule
          ),
        data: { breadcrumbs: BreadcrumbResolver },
        canLoad: [KeycloakGuard, HalGuard],
        canActivateChild: [KeycloakGuard]
      },
      {
        path: 'meine-Daten',
        loadChildren: () =>
          import('./my-data/my-data.module').then(m => m.MyDataModule),
        data: { breadcrumbs: BreadcrumbResolver },
        canLoad: [KeycloakGuard, HalGuard],
        canActivateChild: [KeycloakGuard]
      },
      {
        path: 'wettkampforganisation',
        loadChildren: () =>
          import(
            './competition-organisation/competition-organisation.module'
          ).then(m => m.CompetitionOrganisationModule),
        data: { breadcrumbs: BreadcrumbResolver },
        canLoad: [KeycloakGuard, HalGuard],
        canActivateChild: [KeycloakGuard]
      }
    ]
  },
  {
    path: 'status',
    loadChildren: () =>
      import('./status/status.module').then(m => m.StatusModule),
    data: { breadcrumbs: BreadcrumbResolver }
  },
  {
    path: '*',
    redirectTo: 'module'
  },
  {
    path: '**',
    redirectTo: 'module'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
