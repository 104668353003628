import { Pipe, PipeTransform } from '@angular/core';
import { DocumentListItem } from '@ssmm-shared/dialogs/upload-docs-dialog/data/document-list-item.interface';

@Pipe({
  name: 'toDeleteDocCancelUploadDialogTitle'
})
export class ToDeleteDocCancelUploadDialogTitlePipe implements PipeTransform {
  transform(documentToDelete: DocumentListItem | undefined): unknown {
    return `Dokument "${documentToDelete?.name}" löschen`;
  }
}
