import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';
import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { CompetitionAssignee } from '@ssmm-shared/data/models/competition/competition-assignee.interface';
import { map, tap } from 'rxjs/operators';
import { KendoDropdownItem } from '@ssmm-shared/data/kendo-dropdown-item.interface';
import { MeldeschlussPassedPipe } from '../../../shared-modules/competition-registration/register-teams/pipes/meldeschluss-passed.pipe';

export interface SearchFormModel {
  userSearchValue: string;
  geoSearchValue: string;
}

@Component({
  selector: 'ssmm-bea-assignment-dialog',
  templateUrl: './bea-assignment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeaAssignmentDialogComponent implements OnInit {
  // #region [OUT]
  @Output()
  beaSelected: EventEmitter<CompetitionAssignee> =
    new EventEmitter<CompetitionAssignee>();
  // #endregion

  title: string;
  isVisible: boolean;

  // #region [OBS]
  beas$: Observable<CompetitionAssignee[]>;
  beasFiltered$: Observable<CompetitionAssignee[]>;
  nextBeas$: Observable<CompetitionAssignee[]>;
  searchForm: UntypedFormGroup;
  // #region [OBS]

  formModel: { [key in keyof SearchFormModel]?: UntypedFormControl } = {
    userSearchValue: new UntypedFormControl(null),
    geoSearchValue: new UntypedFormControl(null)
  };

  geoEbeneList: KendoDropdownItem[];
  dropdownDefaultEbeneItem: KendoDropdownItem;
  nextGeoEbeneText = 'Nächste Ebene';

  selectedBeas$: Observable<CompetitionAssignee[]>;

  private meldeschlussPassedPipie = new MeldeschlussPassedPipe();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.searchForm = this._fb.group(this.formModel);
  }

  open(
    title: string,
    availableBeaGeos$: Observable<CompetitionAssignee[]>,
    mannschaftAbgabeEbeneList?: string[],
    nextAvailableBeaGeos$?: Observable<CompetitionAssignee[]>
  ): void {
    this.beas$ = availableBeaGeos$;
    this.beasFiltered$ = availableBeaGeos$;
    this.nextBeas$ = nextAvailableBeaGeos$;
    this.selectedBeas$ = availableBeaGeos$;
    this.title = title;
    this.isVisible = true;

    if (mannschaftAbgabeEbeneList?.length > 1) {
      this.dropdownDefaultEbeneItem = <KendoDropdownItem>{
        text: 'Aktuelle Ebene',
        value: mannschaftAbgabeEbeneList[1]
      };

      this.geoEbeneList = [
        <KendoDropdownItem>{
          text: this.nextGeoEbeneText,
          value: mannschaftAbgabeEbeneList[0]
        }
      ];
    }

    this._cdr.markForCheck();
  }

  userSelected(competitionAssignee: CompetitionAssignee): void {
    this.beaSelected.emit(competitionAssignee);
    this.close();
  }

  close(): void {
    this.dropdownDefaultEbeneItem = undefined;
    this.geoEbeneList = [];
    this.isVisible = false;
    this._cdr.markForCheck();
  }

  search(): void {
    if (
      !this.formModel.userSearchValue.value &&
      !this.formModel.geoSearchValue.value
    ) {
      this.beasFiltered$ = this.selectedBeas$;
      return;
    }

    if (
      this.formModel.userSearchValue.value &&
      !this.formModel.geoSearchValue.value
    ) {
      this.beasFiltered$ = this.searchByName(
        this.selectedBeas$,
        this.formModel.userSearchValue.value
      );
      return;
    }

    if (
      !this.formModel.userSearchValue.value &&
      this.formModel.geoSearchValue.value
    ) {
      this.beasFiltered$ = this.searchByGeo(
        this.selectedBeas$,
        this.formModel.geoSearchValue.value
      );
      return;
    }

    this.beasFiltered$ = this.searchByName(
      this.selectedBeas$,
      this.formModel.userSearchValue.value
    ).pipe(res => this.searchByGeo(res, this.formModel.geoSearchValue.value));
  }

  onGeoEbeneChanged($event: KendoDropdownItem): void {
    this.selectedBeas$ =
      $event.text === this.nextGeoEbeneText ? this.nextBeas$ : this.beas$;
    this.search();
  }

  public rowClass = (args: any): { 'k-disabled': boolean } => ({
    'k-disabled': this.meldeschlussPassedPipie.transform(args.dataItem)
  });

  private searchByName(
    items: Observable<CompetitionAssignee[]>,
    searchTerm: string
  ): Observable<CompetitionAssignee[]> {
    return items.pipe(
      map(b =>
        b.filter(
          b1 =>
            b1.nutzer.vorname
              .toLowerCase()
              .startsWith(searchTerm.toLowerCase()) ||
            b1.nutzer.nachname
              .toLowerCase()
              .startsWith(searchTerm.toLowerCase())
        )
      ),
      tap(() => this._cdr.markForCheck())
    );
  }

  private searchByGeo(
    items: Observable<CompetitionAssignee[]>,
    searchTerm: string
  ): Observable<CompetitionAssignee[]> {
    return items.pipe(
      map(b =>
        b.filter(b1 =>
          b1.geo.some(g =>
            g.name.toLowerCase().startsWith(searchTerm.toLowerCase())
          )
        )
      ),
      tap(() => this._cdr.markForCheck())
    );
  }
}
