import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { School } from '@ssmm-shared/data/models/school/school.interface';
import { YesNoDialogComponent } from '@ssmm-shared/dialogs/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'ssmm-school-assignment-dialog',
  templateUrl: './school-assignment-dialog.component.html'
})
export class SchoolAssignmentDialogComponent {
  @Input() title: string;
  @Output() assignSchool = new EventEmitter<School>();

  @ViewChild('schoolAssignmentConfirmationDialog')
  schoolAssignmentConfirmationDialog: YesNoDialogComponent;

  listSchoolsUri: string;
  schoolTypesUri: string;
  selectedSchool: School | undefined;

  private _isDialogOpen: boolean;
  get isDialogOpen(): boolean {
    return this._isDialogOpen;
  }

  open(listSchoolsUri: string, listSchoolTypesUri: string): void {
    this.listSchoolsUri = listSchoolsUri;
    this.schoolTypesUri = listSchoolTypesUri;
    this.resetSchoolSelection();

    this._isDialogOpen = true;
  }

  close(): void {
    this._isDialogOpen = false;
  }

  schoolSelected(school: School): void {
    this.selectedSchool = school;

    this._isDialogOpen = false;
    this.schoolAssignmentConfirmationDialog.open(
      `Jetzt Antrag auf Meldeberechtigung an ${school.name} senden?`
    );
  }

  resetSchoolSelection(): void {
    this.selectedSchool = void 0;

    this._isDialogOpen = true;
  }

  schoolSelectionConfirmed(): void {
    if (!this.selectedSchool) {
      return;
    }

    this.assignSchool.emit(this.selectedSchool);
  }
}
