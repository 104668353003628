import { ModuleWrapperModule } from './module-wrapper/module-wrapper.module';
import { LoadingComponent } from './loading/loading.component';
import { KeycloakService } from 'app/keycloak-service/keycloak.service';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { LOCALE_ID, NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IntlModule, load } from '@progress/kendo-angular-intl';
// Load the required calendar data for the de locale
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/de/calendar';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakHttpInterceptor } from './keycloak-service/keycloak.http';
import { ModulesListComponent } from './modules-list/modules-list.component';
import { NavigationService } from './services/navigation/navigation.service';
import { LogLevel } from './shared/services/log/log-level.enum';
import { SharedModule } from '@ssmm-shared/shared.module';
import { onLoadAppConfig } from '@ssmm-shared/app-config/app-initializer';
import { DynamicAppConfigService } from '@ssmm-shared/app-config/dynamic-app-config.service';
import { NgxStateStackModule } from 'ngx-state-stack';
import { LoadingHttpInterceptor } from './services/interceptors/loading-http.interceptor';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NotificationModule } from '@progress/kendo-angular-notification';

// tslint:disable:max-line-length
/**
 * @see https://www.telerik.com/kendo-angular-ui/components/internationalization/loading-data/#toc-loading-cldr-data
 */
// tslint:enable:max-line-length
load(require('cldr-data/main/de/numbers.json'));

registerLocaleData(localeDE);

const logLevel: LogLevel = environment.production
  ? LogLevel.ERROR
  : LogLevel.DEBUG;

@NgModule({
  declarations: [AppComponent, ModulesListComponent, LoadingComponent],
  imports: [
    BrowserModule,
    IntlModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(logLevel),
    ButtonsModule,
    HttpClientModule,
    BreadcrumbsModule.forRoot(),
    NgxStateStackModule.forRoot(),
    TooltipModule,
    ModuleWrapperModule,
    NotificationModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: onLoadAppConfig,
      multi: true,
      deps: [Injector, DynamicAppConfigService]
    },
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingHttpInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: APP_BASE_HREF, useValue: '/' },
    NavigationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
