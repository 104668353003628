import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Observable } from 'rxjs';
import { Nutzer } from 'ssmm-models';

@Component({
  selector: 'ssmm-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsDialogComponent {
  private _user$: Observable<Nutzer>;

  constructor(private _cdr: ChangeDetectorRef) {}

  get user$(): Observable<Nutzer> {
    return this._user$;
  }

  open(user: Observable<Nutzer>): void {
    this._user$ = user;
    this._cdr.markForCheck();
  }

  close(): void {
    this._user$ = null;
    this._cdr.markForCheck();
  }
}
