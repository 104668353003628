import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equalNumber'
})
export class EqualNumberPipe implements PipeTransform {
  transform(first: number, second: number): boolean {
    return first === second;
  }
}
