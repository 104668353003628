import { Component } from '@angular/core';
import { KeycloakService } from 'app/keycloak-service/keycloak.service';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { NavigationService } from 'app/services/navigation/navigation.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'ssmm-module-wrapper',
  templateUrl: './module-wrapper.component.html',
  styleUrls: ['./module-wrapper.component.scss']
})
export class ModuleWrapperComponent {
  userName: string;
  isRootPath: boolean;
  profileUrl: string;

  constructor(
    private _kcSvc: KeycloakService,
    private _router: Router,
    private _navService: NavigationService
  ) {
    this.userName = (<{ name: string }>(
      KeycloakService.keycloakAuth.tokenParsed
    )).name;

    this.setupSeasonChangedHandler();
    this.setupBreadcrumbChangedHandler();

    this.profileUrl =
      environment.keycloakConfig.url +
      'realms/ssmm/account?referrer=machmit-ng';
  }

  logout(): void {
    this._kcSvc.logout(window.location.origin);
  }

  setupSeasonChangedHandler(): void {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => <NavigationEnd>event)
      )
      .subscribe(event => {
        this.isRootPath =
          event.urlAfterRedirects === '/' ||
          event.urlAfterRedirects === '/module';

        if (!this.isRootPath) {
          this._navService.navigateToSub();
        }
      });
  }

  setupBreadcrumbChangedHandler(): void {
    this._router.events
      .pipe(filter(event => event instanceof ActivationEnd))
      .subscribe(event => {
        const e = <ActivationEnd>event;
        if (!e.snapshot || !e.snapshot.data) {
          return;
        }
        if (!e.snapshot.data['breadcrumbs'] || e.snapshot.url.length === 0) {
          return;
        }

        const bcName = e.snapshot.url[0].toString();
        e.snapshot.data['breadcrumbs'] =
          bcName[0].toUpperCase() +
          bcName.substring(1, bcName.length).replace('-', ' ');
      });
  }
}
