import { Injectable } from '@angular/core';
import { FieldFormat as AppFieldFormat } from '@ssmm-shared/data/models/fixture/field-format.enum';
import { FieldFormat } from 'ssmm-models';

@Injectable({ providedIn: 'root' })
export class MaskedTextboxFormatService {
  toFormValue(
    beValue: string | number | null,
    format: FieldFormat | AppFieldFormat
  ): string | null {
    if (beValue === null) {
      return null;
    }

    const multipliedFloat100 = Math.round(Number(beValue) * 100);

    switch (format) {
      case AppFieldFormat.DD_DD_DD__MINUTES_SECONDS_HUNDRETH:
        const splitNumber = beValue.toString().split('.');

        const minutes = Math.floor(Number(splitNumber[0]) / 60);
        const minutesStr = minutes >= 10 ? minutes.toString() : '0' + minutes;

        const seconds = Math.floor(Number(splitNumber[0]) % 60);
        const secondsStr = seconds >= 10 ? seconds.toString() : '0' + seconds;

        const ms = !splitNumber[1]
          ? '00'
          : splitNumber[1]?.length >= 2
          ? splitNumber[1].toString()
          : splitNumber[1] + '0';

        return minutesStr + secondsStr + ms;
      case AppFieldFormat.DD_DD__SECONDS_HUNDRETH:
        return (
          '0'.repeat(4 - multipliedFloat100.toString().length) +
          multipliedFloat100
        );
      case AppFieldFormat.D_DD__METRES_CENTIMETRES:
        return (
          '0'.repeat(
            3 - multipliedFloat100.toString().replace('.', '').length
          ) + multipliedFloat100
        );
      case AppFieldFormat.DD_DD__METRES_CENTIMETRES:
      case AppFieldFormat.DD_DD__POINTS_DECIMAL:
        return (
          '0'.repeat(
            4 - multipliedFloat100.toString().replace('.', '').length
          ) + multipliedFloat100
        );
      case AppFieldFormat.TYPE_DEFAULT:
        return beValue.toString();
      default:
        throw new Error('Unknown field format ' + format);
    }
  }

  toModelValue(
    value: string | number,
    format: FieldFormat | AppFieldFormat
  ): number {
    if (!value) {
      return null;
    }

    const numVal = Number(value);

    switch (format) {
      case AppFieldFormat.DD_DD_DD__MINUTES_SECONDS_HUNDRETH:
        if (numVal < 1000) {
          return numVal / 100;
        }

        const strVal = numVal.toString();

        let minutes: string;
        let seconds: string;
        let cs: string;

        if (numVal < 10000) {
          minutes = '0';
          seconds = strVal.slice(0, 2);
          cs = strVal.slice(2, 4);
        } else if (numVal < 100000) {
          minutes = strVal.slice(0, 1);
          seconds = strVal.slice(1, 3);
          cs = strVal.slice(3, 5);
        } else {
          minutes = strVal.slice(0, 2);
          seconds = strVal.slice(2, 4);
          cs = strVal.slice(4, 6);
        }

        return Number(minutes) * 60 + Number(seconds) + Number(cs) / 100;
      case AppFieldFormat.DD_DD__SECONDS_HUNDRETH:
      case AppFieldFormat.D_DD__METRES_CENTIMETRES:
      case AppFieldFormat.DD_DD__METRES_CENTIMETRES:
      case AppFieldFormat.DD_DD__POINTS_DECIMAL:
        return numVal / 100;
      case AppFieldFormat.TYPE_DEFAULT:
        return numVal;
      default:
        throw new Error('Unknown field format ' + format);
    }
  }
}
