import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ssmm-grid-header-second-row',
  templateUrl: './grid-header-second-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridHeaderSecondRowComponent {
  @Input() hasSecondRow: boolean;
  @Input() headerText: string;
}
