import { HalService } from './../services/hal.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleWithSeason'
})
export class TitleWithSeasonPipe implements PipeTransform {
  constructor(private _halService: HalService) {}

  transform(_input: unknown): unknown {
    return `${this._halService.pageTitle} ${this._halService.getSaisonYears()}`;
  }
}
