import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ssmm-kendo-grid-search',
  templateUrl: './kendo-grid-search.component.html',
  styleUrls: ['./kendo-grid-search.component.scss']
})
export class KendoGridSearchComponent {
  @Input() control: UntypedFormControl;
  @Output() search = new EventEmitter<void>();

  onSearch(): void {
    this.search.emit();
  }
}
