<kendo-tabstrip (tabSelect)="onTabSelect($event)">
  <kendo-tabstrip-tab
    *ngFor="let tabItem of tabItems; let i = index"
    [title]="tabItem.title"
    [selected]="i === (selectedIndex | async)"
  >
    <ng-template kendoTabContent>
      <ng-content></ng-content>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
