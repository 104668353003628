import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from '@ssmm-shared/data/models/user/user.interface';

@Component({
  selector: 'ssmm-user-selection-dialog',
  templateUrl: './user-selection-dialog.component.html'
})
export class UserSelectionDialogComponent {
  @Input() title: string;
  @Input() getUsersUri: string;
  @Output() userSelected: EventEmitter<User> = new EventEmitter<User>();

  private _isDialogOpen: boolean;

  get isDialogOpen(): boolean {
    return this._isDialogOpen;
  }

  open(): void {
    this._isDialogOpen = true;
  }

  onUserSelected(user: User): void {
    this.userSelected.next(user);
  }

  close(): void {
    this._isDialogOpen = false;
  }
}
