import { Pipe, PipeTransform } from '@angular/core';
import { CompetitionAssignee } from '@ssmm-shared/data/models/competition/competition-assignee.interface';
import dayjs from 'dayjs';

@Pipe({
  name: 'meldeschlussPassed'
})
export class MeldeschlussPassedPipe implements PipeTransform {
  transform(value: CompetitionAssignee): boolean {
    if (!value || !value?.meldeschluss) {
      return false;
    }
    const meldeschluss = dayjs(value.meldeschluss);
    return meldeschluss.isBefore(dayjs());
  }
}
