<div [formGroup]="controlContainer.control">
  <div class="form-group row">
    <div class="col-md-4"></div>

    <div class="col-md-8">
      <kendo-buttongroup
        [selection]="'single'"
        class="d-flex"
        [disabled]="disabled"
      >
        <button
          kendoButton
          [selected]="isUserSelectionSelected"
          [toggleable]="true"
          type="button"
          class="btn-wk-leitung-selection"
          #toggleBtnGetUserFromBackend
          (click)="updateControlState(true, true)"
        >
          Aus Nutzerverzeichnis
        </button>
        <button
          kendoButton
          [toggleable]="true"
          [selected]="!isUserSelectionSelected"
          type="button"
          class="btn-wk-leitung-selection ml-1"
          (click)="updateControlState(false, true)"
        >
          Direkte Eingabe
        </button>
      </kendo-buttongroup>
    </div>
  </div>

  <!-- Vorname -->
  <div class="form-group row">
    <label class="col-md-4 col-form-label">* Vorname</label>
    <div class="col-md-8">
      <ng-container
        *ngIf="
          !isUserSelectionSelected;
          else wkLeitungFirstNameWithButtonTemplate
        "
        [ngTemplateOutlet]="wkLeitungFirstNameTemplate"
      ></ng-container>

      <!-- Only first name - full width -->
      <ng-template #wkLeitungFirstNameTemplate>
        <div>
          <input
            kendoTextBox
            class="form-control"
            formControlName="firstName"
            [disabled]="disabled"
          />
          <ssmm-errors
            [isValidationActive]="wasSaveRequested"
            [control]="userFirstNameRef"
          ></ssmm-errors>
        </div>
      </ng-template>

      <!-- First name with button for user selection - reduced with for input ctl -->
      <ng-template #wkLeitungFirstNameWithButtonTemplate>
        <div class="d-flex">
          <div class="flex-grow-1">
            <ng-container
              *ngTemplateOutlet="wkLeitungFirstNameTemplate"
            ></ng-container>
          </div>
          <div class="user-selection-trigger">
            <button
              [disabled]="disabled"
              kendoButton
              class="awesome-btn-green"
              type="button"
              themeColor="primary"
              (click)="openDialogUserSelection()"
            >
              <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Nachname -->
  <div class="form-group row">
    <label class="col-md-4 col-form-label">* Nachname</label>
    <div class="col-md-8">
      <input
        kendoTextBox
        class="form-control"
        formControlName="lastName"
        [disabled]="disabled"
      />
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="userLastNameRef"
      ></ssmm-errors>
    </div>
  </div>

  <ng-container formGroupName="emailGroup">
    <!-- Mail-Adresse -->
    <div class="form-group row">
      <label class="col-md-4 col-form-label">* Mail-Adresse</label>
      <div class="col-md-8">
        <input
          kendoTextBox
          class="form-control"
          formControlName="email"
          [disabled]="disabled"
        />
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userEmailRef"
        ></ssmm-errors>
      </div>
    </div>

    <!-- Mail-Adresse wiederholen -->
    <div *ngIf="!isUserSelectionSelected" class="form-group row">
      <label class="col-md-4 col-form-label">* Mail-Adresse wiederholen</label>
      <div class="col-md-8">
        <input
          kendoTextBox
          class="form-control"
          formControlName="emailRepeat"
          [disabled]="disabled"
        />
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userEmailRepeatRef"
        ></ssmm-errors>
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="userEmailGroupRef"
        ></ssmm-errors>
      </div>
    </div>
  </ng-container>

  <!-- Mobile-Phone -->
  <div class="form-group row">
    <label class="col-md-4 col-form-label">* Mobiltelefon</label>
    <div class="col-md-8">
      <div class="p-relative">
        <input
          kendoTextBox
          class="form-control"
          formControlName="phone"
          [disabled]="disabled"
        />
        <ssmm-icon-tooltip
          *ngIf="!disabled"
          [tooltipContent]="tooltipContent"
        ></ssmm-icon-tooltip>
      </div>
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="userPhoneRef"
      ></ssmm-errors>
    </div>
  </div>

  <!-- Einverständniserklärung -->
  <div class="row mb-3" [ngClass]="{ 'hide-ctl': isUserSelectionSelected }">
    <div class="col-md-12 d-flex align-items-center">
      <input
        id="confirmationDataProcessingCtl"
        type="checkbox"
        kendoCheckBox
        formControlName="isDataProcessingAllowed"
        [disabled]="disabled"
      />
      <label class="k-checkbox-label" for="confirmationDataProcessingCtl"
        >* Die Einverständniserklärung der eingetragenen Person zur Speicherung
        und Weitergabe ihrer personenbezogenen Daten liegt mir vor.</label
      >
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="isDataProcessingAllowedRef"
      ></ssmm-errors>
    </div>
  </div>

  <!-- WK Leitung validation -->
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-8">
      <input
        formControlName="validation"
        [hidden]="true"
        [disabled]="disabled"
      />
      <!-- Show group error only on selection mode. In other mode, each ctl is validated separately -->
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="userValidationRef"
      ></ssmm-errors>
    </div>
  </div>
</div>

<!-- User Selection Dialog -->
<ssmm-user-selection-dialog
  #dialogUserSelection
  [title]="selectionDialogTitle"
  [getUsersUri]="getUsersUri"
  (userSelected)="userSelected($event)"
></ssmm-user-selection-dialog>
