<ng-container *ngIf="addon">
  <label class="col-md-5 col-form-label"
    ><span *ngIf="addon.mandatory">* </span
    ><span>{{ addon.displayName }}</span></label
  >
  <ng-container [ngSwitch]="addon.type">
    <div class="col-md-7 cbx-column">
      <input
        kendoTextBox
        class="form-control"
        *ngSwitchCase="dataTypeString"
        [formControl]="control"
      />
      <kendo-numerictextbox
        class="form-control"
        *ngSwitchCase="dataTypeInt"
        [format]="'n'"
        [formControl]="control"
      ></kendo-numerictextbox>

      <ng-container *ngSwitchCase="dataTypeBoolean">
        <input
          [id]="addon.name + 'Cbx'"
          kendoCheckBox
          type="checkbox"
          class="k-checkbox form-control"
          [formControl]="control"
        />
        <label class="k-checkbox-label" [for]="addon.name + 'Cbx'"></label>
      </ng-container>

      <ssmm-errors
        [control]="control"
        [isValidationActive]="isValidationActive"
      ></ssmm-errors>
    </div>
  </ng-container>
</ng-container>
