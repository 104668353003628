import { UntypedFormGroup } from '@angular/forms';

export class EqualsValidator {
  static areEqual(fg: UntypedFormGroup): { [error: string]: any } {
    const keys: string[] = Object.keys(fg.controls);

    const values = keys.map(k => fg.get(k).value);

    // In case all values are empty, return valid
    if (values.every(v => !v)) {
      return {};
    }

    // TODO: Replace with: https://lodash.com/docs/4.17.10#uniq
    const equalsReducer = (first: string, second: string) =>
      first === second ? first : '';
    const uniqueValues = values.reduce(equalsReducer);

    if (!uniqueValues) {
      return { error: 'Die Werte stimmen nicht überein.' };
    }

    return {};
  }
}
