<form class="k-form" (ngSubmit)="addEntry()" [formGroup]="formGroup" novalidate>
  <!-- New Value -->
  <div class="row">
    <div class="col-md-5 col-sm-5">
      <label [for]="valueCtl">{{ valueHeader }}</label>
    </div>
    <div class="col-md-5 col-sm-5">
      <label [for]="keyCtl">{{ keyHeader }}</label>
    </div>
  </div>

  <!-- New Key -->
  <div class="row">
    <div class="col-md-5 col-sm-5 align-self-center">
      <input
        class="form-control"
        kendoTextBox
        #valueCtl
        [formControl]="formModel.attributeName"
      />
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="formModel.attributeName"
      ></ssmm-errors>
    </div>
    <div class="col-md-5 col-sm-5 align-self-center">
      <input
        class="form-control"
        kendoTextBox
        #keyCtl
        [formControl]="formModel.attributeKuerzel"
      />
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="formModel.attributeKuerzel"
      ></ssmm-errors>
    </div>

    <!-- CTAs -->
    <div class="col-md-2 col-sm-2 align-self-center">
      <button
        class="awesome-btn-green"
        kendoButton
        themeColor="primary"
        type="submit"
      >
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
      </button>
    </div>
  </div>

  <!-- Validation -->
  <div class="row">
    <div class="col-md-10 col-sm-10">
      <ssmm-errors
        [isValidationActive]="wasSaveRequested"
        [control]="formGroup"
      ></ssmm-errors>
    </div>
  </div>

  <!-- List of added DEF1 -->
  <div class="row mt-3" *ngFor="let item of listItems">
    <div class="col-md-5 col-sm-5 align-self-center">
      <input class="form-control" kendoTextBox [value]="item.name" disabled />
    </div>
    <div class="col-md-5 col-sm-5 align-self-center">
      <input
        class="form-control"
        kendoTextBox
        [value]="item.kuerzel"
        disabled
      />
    </div>
    <div class="col-md-2 col-sm-2">
      <button
        class="awesome-btn-red"
        kendoButton
        themeColor="primary"
        [disabled]="!item.aenderbar"
        (click)="removeEntry(item)"
      >
        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
      </button>
    </div>
  </div>
</form>
