import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areAllItemsSelected'
})
export class AreAllItemsSelectedPipe implements PipeTransform {
  transform(allData: unknown[], selectedData: unknown[]): unknown {
    return allData?.length === selectedData?.length;
  }
}
