/*
 * Copyright 2017 Red Hat, Inc. and/or its affiliates
 * and other contributors as indicated by the @author tags.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Injectable } from '@angular/core';

// If using a local keycloak.js, uncomment this import.  With keycloak.js fetched
// from the server, you get a compile-time warning on use of the Keycloak()
// method below.  I'm not sure how to fix this, but it's certainly cleaner
// to get keycloak.js from the server.
//
import Keycloak from 'keycloak-js';
import { KeycloakLoginOptions, KeycloakConfig } from 'keycloak-js';

export type KeycloakClient = Keycloak.KeycloakInstance;
type InitOptions = Keycloak.KeycloakInitOptions;

@Injectable()
export class KeycloakService {
  static keycloakAuth: KeycloakClient;

  /**
   * Configure and initialize the Keycloak adapter.
   *
   * @param configOptions Optionally, a path to keycloak.json, or an object containing
   *                      url, realm, and clientId.
   * @param adapterOptions Optional initiaization options.  See javascript adapter docs
   *                       for details.
   * @returns {Promise<T>}
   */
  static init(
    configOptions?: string | KeycloakConfig,
    initOptions?: InitOptions
  ): Promise<any> {
    KeycloakService.keycloakAuth = Keycloak(configOptions);

    return new Promise((resolve, reject) => {
      KeycloakService.keycloakAuth
        .init(initOptions)
        .then(() => {
          resolve(null);
        })
        .catch((errorData: any) => {
          reject(errorData);
        });
    });
  }

  /**
   * Expose the underlying Keycloak javascript adapter.
   */
  client(): KeycloakClient {
    return KeycloakService.keycloakAuth;
  }

  authenticated(): boolean {
    return KeycloakService.keycloakAuth.authenticated;
  }

  login(options?: KeycloakLoginOptions): void {
    KeycloakService.keycloakAuth.login(options);
  }

  logout(redirectUri?: string): void {
    KeycloakService.keycloakAuth.logout({ redirectUri: redirectUri });
  }

  account(): void {
    KeycloakService.keycloakAuth.accountManagement();
  }

  authServerUrl(): string {
    return KeycloakService.keycloakAuth.authServerUrl;
  }

  realm(): string {
    return KeycloakService.keycloakAuth.realm;
  }

  getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!KeycloakService.keycloakAuth.token) {
        reject('Not logged in');
      }

      KeycloakService.keycloakAuth
        .updateToken(5)
        .then(() => {
          resolve(<string>KeycloakService.keycloakAuth.token);
        })
        .catch(() => {
          reject('Failed to refresh token');
          KeycloakService.keycloakAuth.logout();
        });
    });
  }
}
