import { Pipe, PipeTransform } from '@angular/core';
import { MaskedTextboxFormatService } from '@ssmm-shared/services/masked-textbox-format.service';
import { FieldFormat } from 'ssmm-models';

@Pipe({
  name: 'maskedTextboxFormValue'
})
export class MaskedTextboxFormValuePipe implements PipeTransform {
  constructor(private _formatService: MaskedTextboxFormatService) {}

  transform(value: number | null, format: FieldFormat): string {
    if (!value) {
      return '';
    }

    return this._formatService.toFormValue(value, format);
  }
}
