import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Injector } from '@angular/core';
import { DynamicAppConfigService } from './dynamic-app-config.service';

export function onLoadAppConfig(
  injector: Injector,
  dynamicAppConfigService: DynamicAppConfigService
): () => Promise<any> {
  return (): Promise<any> => {
    console.log(`Starting app version ${environment.version}`);
    console.log('Loading app config...');
    const route = document.location.pathname;
    const router: Router = injector.get(Router);

    if (route.startsWith('/status')) {
      return router.navigateByUrl(route);
    }

    return (
      dynamicAppConfigService
        .setupKeycloak()
        // After everything is loaded, start the routing process
        .then(() => {
          console.log(
            `Initial navigation of app version ${environment.version} starting...`
          );

          router.initialNavigation();
        })
    );
  };
}
