<div class="search-box">
  <input
    [formControl]="control"
    kendoTextBox
    class="dialog-search-header"
    (input)="onSearch()"
  />
  <div class="search-box-placeholder-wrapper">
    <fa-icon
      [icon]="['fas', 'filter']"
      class="search-box-placeholder"
    ></fa-icon>
  </div>
</div>
