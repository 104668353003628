<!-- Assign Kreise Dialog -->
<kendo-dialog title="{{ title }}" *ngIf="isDialogOpen" (close)="close()">
  <ssmm-school-list-with-search
    [schoolTypesUri]="schoolTypesUri"
    [listSchoolsUri]="listSchoolsUri"
    [isAssignmentMode]="true"
    (schoolSelectedEvent)="schoolSelected($event)"
  ></ssmm-school-list-with-search>

  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="close()">Abbrechen</button>
  </kendo-dialog-actions>
</kendo-dialog>

<ssmm-yes-no-dialog
  #schoolAssignmentConfirmationDialog
  [title]="'Meldeberechtigung beantragen'"
  (aborted)="resetSchoolSelection()"
  (confirmed)="schoolSelectionConfirmed()"
></ssmm-yes-no-dialog>
