import { HalService } from './../hal.service';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment
} from '@angular/router';
import { Injectable } from '@angular/core';
import { LogService } from '../log/log.service';

@Injectable({
  providedIn: 'root'
})
export class HalGuard implements CanActivate, CanLoad {
  constructor(
    private _halService: HalService,
    private _router: Router,
    private _log: LogService
  ) {}

  canActivate(
    // @ts-ignore: part of interface
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.getIsMetadataAvailable(state.url);
  }

  // @ts-ignore: part of interface
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.getIsMetadataAvailable(route.path);
  }

  private getIsMetadataAvailable(route: string): boolean {
    const canActivate =
      this._halService.halNavLinks &&
      Object.keys(this._halService.halNavLinks).length > 0;

    if (!canActivate) {
      this._log.error(`HAL Guard restricted loading route ${route}`);
      // This is a workaround, for automatic redirection doesn't work appearently within lazy loaded child routes
      this._router.navigate(['module']);
      return canActivate;
    }

    return canActivate;
  }
}
