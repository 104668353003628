import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationItem } from '../services/data/navigation-item.interface';
import { NavigationLinks } from '../services/data/navigation-links.interface';
import { NavigationService } from '../services/navigation/navigation.service';
import { HALLink } from '@ssmm-shared/data/models/hal-link.interface';
import { HalService } from '@ssmm-shared/services/hal.service';
import { TreeviewItem } from '@ssmm-shared/treeview/treeview-item';
import { NavigationResponse } from '../services/data/navigation-response.interface';

@Component({
  selector: 'ssmm-modules-list',
  templateUrl: './modules-list.component.html'
})
export class ModulesListComponent implements OnInit, OnDestroy {
  treeItemsList: TreeviewItem[] = [];
  private _navSubscription: Subscription;

  constructor(
    private _navService: NavigationService,
    private _halService: HalService
  ) {}

  ngOnInit(): void {
    // subscribe to year changes
    this._navSubscription = this._navService.getNavigation().subscribe(res => {
      this.refreshNavigation(res);
    });

    // initial loading of season
    this._navService.refreshNavigation(this._halService.getSaisonLink());
  }

  ngOnDestroy(): void {
    this._navSubscription.unsubscribe();
  }

  private refreshNavigation(res: NavigationResponse): void {
    if (!res) {
      return;
    }

    if (res.viewedSaison) {
      this._halService.viewedSaison = res.viewedSaison;
    }
    this.setTreeViewItems(res.nodes);
  }

  private setTreeViewItems(navItems: NavigationItem[]): void {
    // Clear list first
    this.treeItemsList.length = 0;
    navItems.forEach(n => {
      this.treeItemsList.push(this.mapDataToTreeViewItems(n));
    });
  }

  private mapDataToTreeViewItems(navItem: NavigationItem): TreeviewItem {
    return <TreeviewItem>{
      title: navItem.title,
      route: navItem.route
        ? navItem.route.replace(new RegExp('_', 'g'), '/')
        : '',
      icon: navItem.icon,
      children: navItem.childNodes.map(child =>
        this.mapDataToTreeViewItems(child)
      ),
      links: this.getHALLink(navItem._links)
    };
  }

  private getHALLink(links: NavigationLinks): string[] {
    const parsedLinks: string[] = [];

    Object.keys(links).forEach(
      l => (parsedLinks[l] = (<HALLink>links[l]).href)
    );
    return parsedLinks;
  }
}
