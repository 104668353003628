import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
import { User } from '@ssmm-shared/data/models/user/user.interface';
import { UserSelectionDialogComponent } from '@ssmm-shared/dialogs/user-selection-dialog/user-selection-dialog.component';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserChangeItem } from '@ssmm-shared/data/models/user/user-change-item.interface';

@Component({
  selector: 'ssmm-user-selection-toggle',
  templateUrl: './user-selection-toggle.component.html',
  styleUrls: ['./user-selection-toggle.component.scss']
})
export class UserSelectionToggleComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Input() wasSaveRequested: boolean;
  @Input() getUsersUri: string;
  @Input() selectionDialogTitle: string;

  @Input() userChangeItem$: Observable<UserChangeItem>;

  @Output() userSelectionChange = new EventEmitter<User>();

  @ViewChild(UserSelectionDialogComponent)
  dialogUserSelection: UserSelectionDialogComponent;

  readonly tooltipContent = `Eingaberegeln:<br>
  * Nur Ziffern.<br>
  * Keine Leerzeichen.<br>
  * Muss mit einer "0" starten.`;

  isUserSelectionSelected: boolean;

  private readonly _unsubscribe = new Subject<void>();

  private _latestNutzerverzeichnisUser: User;

  constructor(public controlContainer: ControlContainer) {}

  // #region [Getters]
  get userFirstNameRef(): AbstractControl {
    return this.controlContainer.control.get('firstName');
  }

  get userLastNameRef(): AbstractControl {
    return this.controlContainer.control.get('lastName');
  }

  get userEmailRef(): AbstractControl {
    return this.controlContainer.control.get(['emailGroup', 'email']);
  }

  get userEmailRepeatRef(): AbstractControl {
    return this.controlContainer.control.get(['emailGroup', 'emailRepeat']);
  }

  get userEmailGroupRef(): AbstractControl {
    return this.controlContainer.control.get('emailGroup');
  }

  get userPhoneRef(): AbstractControl {
    return this.controlContainer.control.get('phone');
  }

  get isDataProcessingAllowedRef(): AbstractControl {
    return this.controlContainer.control.get('isDataProcessingAllowed');
  }

  get inputCtls(): AbstractControl[] {
    return [
      this.userFirstNameRef,
      this.userLastNameRef,
      this.userEmailRef,
      this.userEmailRepeatRef,
      this.userPhoneRef,
      this.isDataProcessingAllowedRef
    ];
  }

  get userValidationRef(): AbstractControl {
    return this.controlContainer.control.get('validation');
  }
  // #endregion

  ngOnInit(): void {
    this.userChangeItem$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(userChangeItem => {
        if (!userChangeItem?.user) {
          return;
        }

        this.isUserSelectionSelected = userChangeItem.isUserSelectionSelected;

        if (this.isUserSelectionSelected) {
          this._latestNutzerverzeichnisUser = userChangeItem.user;
        }

        this.updateControlState(this.isUserSelectionSelected, false);
        this.setUserFormValues(userChangeItem.user);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  updateControlState(
    isUserSelectionRequested: boolean,
    isUserAction: boolean
  ): void {
    const isStateUnchanged = this.checkIsStateUnchanged(
      isUserAction,
      isUserSelectionRequested,
      this.isUserSelectionSelected
    );
    if (isStateUnchanged) {
      return;
    }

    this.isUserSelectionSelected = isUserSelectionRequested;

    if (isUserSelectionRequested) {
      this.setUserFormValues(this._latestNutzerverzeichnisUser);

      this.inputCtls.forEach(c => c.disable());

      if (isUserAction) {
        this.userSelectionChange.emit(this._latestNutzerverzeichnisUser);
      }
      return;
    }

    this.inputCtls.forEach(c => c.reset());

    this.userValidationRef.setValue('ok');
    this.inputCtls.forEach(c => c.enable());

    if (isUserAction) {
      this.inputCtls.forEach(c => c.markAsDirty());
    } else {
      this.isDataProcessingAllowedRef.setValue(true);
    }

    this.userSelectionChange.emit(null);
  }

  userSelected(user: User): void {
    if (!user) {
      this.userValidationRef.reset();
      return;
    }

    this.setUserFormValues(user);

    this.controlContainer.control.updateValueAndValidity();
    this.controlContainer.control.markAsDirty();

    this._latestNutzerverzeichnisUser = user;
    this.userSelectionChange.emit(user);
    this.dialogUserSelection.close();
  }

  openDialogUserSelection(): void {
    this.dialogUserSelection.open();
  }

  private checkIsStateUnchanged(
    isUserAction: boolean,
    isUserSelectionRequested: boolean,
    isUserSelectionSelected: boolean
  ): boolean {
    if (!isUserAction) {
      return false;
    }

    return (
      (isUserSelectionSelected && isUserSelectionRequested) ||
      (!isUserSelectionSelected && !isUserSelectionRequested)
    );
  }

  private setUserFormValues(user: User): void {
    this.userFirstNameRef.setValue(user.vorname);
    this.userLastNameRef.setValue(user.nachname);
    this.userEmailRef.setValue(user.email);
    this.userEmailRepeatRef.setValue(user.email);
    this.userPhoneRef.setValue(user.mobiltelefon);
    this.userValidationRef.setValue('ok');
  }
}
