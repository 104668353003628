import { HalService } from './../services/hal.service';
import { Component, Input } from '@angular/core';
import { TreeviewItem } from './treeview-item';
import { Router } from '@angular/router';

@Component({
  selector: 'ssmm-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent {
  @Input() treeItems: TreeviewItem[];
  @Input() amIchild: boolean;

  constructor(private _router: Router, private _halService: HalService) {}

  loadRoute(item: TreeviewItem): void {
    this._halService.halNavLinks = item.links;
    this._halService.pageTitle = item.title;
    this._router.navigate(['/module/' + item.route]);
  }
}
