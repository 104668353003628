export const environment = {
  production: false,
  version: require('../../package.json').version,
  apiBaseUrl: '/',
  keycloakConfig: {
    url: '/auth/',
    realm: 'ssmm',
    clientId: 'machmit-ng'
  }
};
