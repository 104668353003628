import { Pipe, PipeTransform } from '@angular/core';
import { TeilnehmerStartposition } from '../../../../projects/ssmm-models/src/lib/typescript-client';

@Pipe({
  name: 'amountProperties'
})
export class AmountPropertiesPipe implements PipeTransform {
  transform(
    startPositions: TeilnehmerStartposition[],
    ..._unusedParams: unknown[]
  ): number {
    if (startPositions === undefined) {
      return 0;
    }
    return startPositions.reduce(
      (acc, val) => acc + (val.startposition >= 1 ? 1 : 0),
      0
    );
  }
}
