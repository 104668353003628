import { Observable } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ssmm-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  isLoading$: Observable<boolean>;

  constructor(loadingService: LoadingService) {
    this.isLoading$ = loadingService.isLoading$;
  }
}
