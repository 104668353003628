import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageGroupBack'
})
export class AgeGroupBackPipe implements PipeTransform {
  transform(text: string, schoolYear: number): number {
    if (text === 'offen') {
      // tslint:disable-next-line
      return void 0;
    }
    if (text === 'Kl. 1') {
      return 1;
    }
    if (text === 'Kl. 2') {
      return 2;
    }
    if (text === 'Kl. 3') {
      return 3;
    }
    if (text === 'Kl. 4') {
      return 4;
    }
    return schoolYear - Number(text);
  }
}
