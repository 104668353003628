import { FooterModule } from './../footer/footer.module';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { AwesomeIconsModule } from './../awesome-icons/awesome-icons.module';
import { RouterModule } from '@angular/router';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { ModuleWrapperComponent } from './module-wrapper.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonGroupModule,
  ButtonModule
} from '@progress/kendo-angular-buttons';
import { IsSeasonSelectedPipe } from './header-nav/pipes/is-season-selected.pipe';

@NgModule({
  declarations: [
    ModuleWrapperComponent,
    HeaderNavComponent,
    IsSeasonSelectedPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    AwesomeIconsModule,
    ButtonGroupModule,
    ButtonModule,
    BreadcrumbsModule,
    FooterModule
  ],
  exports: [ModuleWrapperComponent]
})
export class ModuleWrapperModule {}
