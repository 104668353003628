import { GeoAssignment } from '@ssmm-shared/data/models/geo/geo-assignment.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'geoNamesSeparated'
})
export class GeoNamesSeparatedPipe implements PipeTransform {
  transform(value: GeoAssignment[], separator: string): string {
    return value.map(v => v.name).join(separator);
  }
}
