<kendo-dialog
  [title]="dialogTitle"
  *ngIf="isDialogOpened"
  [width]="800"
  (close)="close()"
>
  <form class="k-form" [formGroup]="userCreationForm" novalidate>
    <ssmm-user-creation-form
      [wasSaveRequested]="wasSaveRequested"
      [isRbBeauftragung]="isRbBeauftragung"
    ></ssmm-user-creation-form>
  </form>
  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="close()">Schließen</button>
    <button
      type="button"
      kendoButton
      themeColor="primary"
      [disabled]="isSaveInProgress"
      (click)="onSaveAndAssignRequested()"
    >
      Speichern
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
