import { Pipe, PipeTransform } from '@angular/core';
import { SchoolType } from '@ssmm-shared/data/models/school/school-type.interface';

@Pipe({
  name: 'schoolTypesShort'
})
export class SchoolTypesShortPipe implements PipeTransform {
  // @ts-ignore: part of interface
  transform(schoolTypes: SchoolType[], args?: any): string {
    if (!schoolTypes) {
      return '';
    }

    let parsed = schoolTypes.map(r => r.kuerzel).join(',');
    const re = new RegExp(',', 'g');
    parsed = parsed.replace(re, ', ');

    return parsed;
  }
}
