import { Pipe, PipeTransform } from '@angular/core';
import { Sex } from '../data/sex.enum';

@Pipe({
  name: 'sex'
})
export class SexPipe implements PipeTransform {
  transform(sexKey: string): string {
    return Sex[sexKey];
  }
}
