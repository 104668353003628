import { Component, Input } from '@angular/core';

@Component({
  selector: 'ssmm-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent {
  @Input() item: { key: string; value: string };
}
